export const str_2_ru = `
1
00:00:01,720 --> 00:00:03,360
Английское пение.

2
00:00:05,060 --> 00:00:06,420
Песня тела.

5
00:00:12,120 --> 00:00:13,160
Ага!

6
00:00:13,160 --> 00:00:14,400
Ну давай же!

7
00:00:14,400 --> 00:00:16,400
Песня тела!

8
00:00:17,720 --> 00:00:19,180
Боп-боп-боп

9
00:00:19,180 --> 00:00:21,000
Тело тело боп-боп-боп.

10
00:00:21,100 --> 00:00:22,320
Боп-боп-боп.

11
00:00:22,320 --> 00:00:24,340
Тело тело боп-боп.

12
00:00:24,540 --> 00:00:26,020
Касаюсь моих глаз.

13
00:00:26,160 --> 00:00:27,640
Касаюсь моих ушей.

14
00:00:27,820 --> 00:00:29,360
Касаюсь моего носа.

15
00:00:29,360 --> 00:00:31,060
Касаюсь моего рта.

16
00:00:31,200 --> 00:00:34,160
Касаюсь, касаюсь моего тела.

17
00:00:34,500 --> 00:00:35,820
Боп-боп-боп.

18
00:00:35,820 --> 00:00:37,160
Все боп-боп.

19
00:00:37,840 --> 00:00:38,840
Боп-боп-боп.

20
00:00:38,840 --> 00:00:40,840
Тело тело боп-боп-боп.

21
00:00:41,020 --> 00:00:42,020
Боп-боп-боп.

22
00:00:42,140 --> 00:00:44,140
Тело тело боп-боп.

23
00:00:44,340 --> 00:00:46,000
Качаю головой.

24
00:00:46,000 --> 00:00:47,640
Трясу плечами.

25
00:00:47,800 --> 00:00:49,220
Трясу талией.

26
00:00:49,220 --> 00:00:50,920
Трясу бедрами.

27
00:00:50,960 --> 00:00:54,300
Трясу трясу трясу моим телом.

28
00:00:54,440 --> 00:00:55,980
Боп-боп-боп.

29
00:00:55,980 --> 00:00:57,320
Все боп-боп.

30
00:00:57,660 --> 00:00:58,800
Боп-боп-боп.

31
00:00:58,920 --> 00:01:00,380
Тело тело боп-боп-боп.

32
00:01:00,380 --> 00:01:02,000
Боп-боп-боп.

33
00:01:02,120 --> 00:01:03,840
Тело тело боп-боп-боп.

34
00:01:04,099 --> 00:01:05,880
Касаюсь моих рук.

35
00:01:06,060 --> 00:01:07,400
Касаюсь моих рук.

36
00:01:07,500 --> 00:01:09,140
Касаюсь моих ног.

37
00:01:09,140 --> 00:01:10,820
Касаюсь моих ног.

38
00:01:10,820 --> 00:01:13,900
Касаюсь касаюсь касаюсь моего тела.

39
00:01:14,160 --> 00:01:15,340
Боп-боп-боп.

40
00:01:15,400 --> 00:01:17,000
Все боп-боп.

41
00:01:17,220 --> 00:01:18,220
Ага!

`