export const str_7_ru = `
1
00:00:00,270 --> 00:00:13,510
- Эй, если ты работаешь в
компания, в коллективе и ты по крайней мере
разработчик среднего уровня,
время от времени,
ваша команда просит вас
брать интервью у новых людей, которые
хотел бы присоединиться к команде.

5
00:00:13,510 --> 00:00:15,130
Итак, вы будете интервьюером.

6
00:00:15,130 --> 00:00:23,940
Я играю эту роль несколько раз
в неделю, это точно,
и есть проблема с интервью
когда вы являетесь активным интервьюером.

9
00:00:23,940 --> 00:00:27,963
На самом деле проблема еще больше
когда ты не очень
активный интервьюер.

11
00:00:28,930 --> 00:00:36,240
Проблема в том, как же объяснить свое мнение
о человеке, у которого вы берете интервью
остальной команде?

13
00:00:36,240 --> 00:00:42,300
Вам дадут программатор,
они попросят вас взять интервью у этого парня
а потом после интервью
они спросят вас, что вы думаете?

17
00:00:42,300 --> 00:00:45,860
И вы должны объяснить свои выводы.

18
00:00:45,860 --> 00:00:48,800
Вы должны подвести итог своей
впечатления после собеседования.

19
00:00:48,800 --> 00:00:56,780
И что еще интересно, так это то, что
они будут иногда спрашивать вас
дать это резюме через некоторое время.

22
00:00:56,780 --> 00:01:01,480
Они спросят вас прямо после интервью,
но, может быть и через некоторое время, 
а может быть даже через несколько недель.

24
00:01:01,480 --> 00:01:04,129
И трудно, трудно вспомнить
всех людей, которых вы интервьюируете.

26
00:01:04,129 --> 00:01:06,840
Трудно вспомнить
почему вы даете именно такое мнение.

27
00:01:06,840 --> 00:01:10,053
Поэтому делюсь своим рецептом, как я это делаю.

28
00:01:11,780 --> 00:01:18,520
Я создаю очень маленький файл для себя,
очень маленький шаблон
с самыми важными вопросами

30
00:01:18,520 --> 00:01:22,960
Я спрашиваю людей, у которых беру интервью,
и это не технические вопросы.

32
00:01:22,960 --> 00:01:34,760
Я действительно не указываю
точные вопросы по Java, которые я могу задать,
но я сломал все аспекты
вопросов, которые я задаю этому парню.

35
00:01:34,760 --> 00:01:45,670
И тогда я примерно
определил диапазоны
в котором я измеряю ответы, которые слышу.

37
00:01:45,670 --> 00:01:50,670
Например, я обращаю внимание
на историю с открытым исходным кодом
людей, у которых я беру интервью.

39
00:01:50,670 --> 00:01:52,200
Так что для меня это имеет значение.

40
00:01:52,200 --> 00:01:56,700
Когда ты придешь ко мне на собеседование,
Я спрошу тебя, есть ли у тебя
опыт работы с открытым исходным кодом?

42
00:01:56,700 --> 00:02:01,480
Вы пытались совершить
некоторые проекты с открытым исходным кодом,
или, может быть, у вас есть свой
собственные проекты с открытым исходным кодом?

44
00:02:01,480 --> 00:02:02,570
Такие вопросы.

45
00:02:02,570 --> 00:02:05,100
Если ваш ответ ноль, у меня ничего нет.

46
00:02:05,100 --> 00:02:08,330
Тогда я даю вам ноль в
именно эту линию.

47
00:02:08,330 --> 00:02:16,620
Если ты скажешь мне, что ты
являетесь активным участником
к нескольким крупным продуктам с открытым исходным кодом
и у вас есть свои вещи,
тогда я дам вам семь, 10.

50
00:02:16,620 --> 00:02:18,780
Мой диапазон от нуля до 10.

51
00:02:18,780 --> 00:02:26,080
И тут я быстро всего одним предложением,
объяснить себе, почему
Я дал вам это замечание,
почему я дал вам пять.

54
00:02:26,080 --> 00:02:28,160
Я просто добавил несколько слов, чтобы запомнить.

55
00:02:28,160 --> 00:02:31,130
И у меня есть другие вещи, такие как образование.

56
00:02:31,130 --> 00:02:33,250
Я обращаю внимание на то, где вы учились.

57
00:02:33,250 --> 00:02:40,720
Одно дело ты бакалавр,
а может без образования
и не учился ни в одном университете, или, может быть,
вы получаете докторскую степень в Стэнфорде.

59
00:02:40,720 --> 00:02:46,123
Таким образом, в диапазоне от нуля до
10, я поставлю тебе оценку
и я положу несколько слов там.

61
00:02:46,980 --> 00:03:02,040
Еще на что я обращаю внимание
Java, Ruby, C++ программистов,
я даю очень маленький кусок кода
и прошу людей найти ошибки или
проблемы в этом фрагменте кода.

66
00:03:02,040 --> 00:03:09,890
И судя по их ответам,
Я делаю выводы во время интервью
насколько они хороши или плохи,
а иногда дело не в хорошем или плохом.

70
00:03:09,890 --> 00:03:14,460
На самом деле, часто это
не о хорошем или плохом,
это о том, какие ошибки они находят.

72
00:03:14,460 --> 00:03:17,240
Некоторые люди видят там проблемы низкого уровня.

73
00:03:17,240 --> 00:03:20,210
Некоторые люди видят высокий уровень
баги, проблемы с дизайном.

74
00:03:20,210 --> 00:03:25,380
Так что дело не в этом
судя по вашему уровню,
но о том, что судить
вы обратите внимание.

76
00:03:25,380 --> 00:03:30,400
Мол, какой же ты дизайнер,
или ты программист,
а может архитектор?

78
00:03:30,400 --> 00:03:37,360
Итак, я записываю свое суждение,
Я записываю свои выводы,
и я даю вам число от нуля до 10.

80
00:03:37,360 --> 00:03:38,620
Затем я заполняю эту форму.

81
00:03:38,620 --> 00:03:40,620
Это простой одностраничный документ.

82
00:03:40,620 --> 00:03:42,100
Я поместил туда некоторые детали.

83
00:03:42,100 --> 00:03:48,310
Дата интервью,
какая главная информация которую я получил от вас.

85
00:03:48,310 --> 00:03:55,240
Очень коротко, очень кратко,
и я сохранил этот документ на свой компьютер,
и у меня есть эти документы
за последние годы.

88
00:03:55,240 --> 00:03:58,380
Я могу вернуться на несколько лет
назад, и я могу открыть.

89
00:03:58,380 --> 00:04:13,850
Например, я брал интервью
у тебя несколько лет назад
ты снова возвращаешься ко мне,
затем я открываю файл
и я знаю какой
вопросы, которые я задавал тебе,
какие ответы я получил,
и почему я так решил
твой уровень здесь пятый,
три здесь, семь здесь,
и так далее и тому подобное.

95
00:04:13,850 --> 00:04:15,380
Я предлагаю вам сделать то же самое.

96
00:04:15,380 --> 00:04:28,310
Определите для себя,
вещи, на которые вы обращаете внимание,
определить диапазоны, определить
как вы оцениваете ответы,
и сделать этот шаблон таким же
для всех, у кого вы берете интервью.

99
00:04:28,310 --> 00:04:32,320
И не ждите, что ваша компания или ваша команда
предоставит вам этот шаблон.

101
00:04:32,320 --> 00:04:33,810
Иногда так и было.

102
00:04:33,810 --> 00:04:42,000
Крупные компании
они обычно ждут тебя
как-то структурировать
результат собеседования,
как-то структурировать свой вывод.

105
00:04:42,000 --> 00:04:51,000
Но я бы предложил
по-прежнему делать свою работу
и определите свой собственный шаблон,
свой собственный способ интервьюировать людей,
на что вы обращаете внимание.

109
00:04:51,000 --> 00:04:55,140
И придерживайтесь этого шаблона
на протяжении лет когда вы делаете интервью.

111
00:04:55,140 --> 00:05:00,870
В этом случае будет
вам проще сравнить
кандидат кандидату
потому что вы можете видеть цифры.

113
00:05:00,870 --> 00:05:11,280
Хоть люди и разные,
конечно трудно
сравните их по номерам,
но все же ты сможешь
объяснить своей команде
почему этот кандидат подходит,
а этот не подходит.

117
00:05:11,280 --> 00:05:18,300
Таким образом, это объяснение будет основано
на некоторой объективной информации,
объективные измерения.

119
00:05:18,390 --> 00:05:22,910
Конечно, они не совсем объективны,
но все же это лучше, чем
субъективное мнение.

121
00:05:22,910 --> 00:05:24,790
Мне нравится этот парень, мне не нравится этот парень.

122
00:05:24,790 --> 00:05:26,490
Администрации сложно объяснить.

123
00:05:26,490 --> 00:05:29,040
Такое ощущение, что ты
не совсем профессионален.

124
00:05:29,040 --> 00:05:37,360
Но если у вас есть документ для этого
и документ для этого, очень короткий,
все же лучше объяснить свое решение.

127
00:05:37,360 --> 00:05:38,620
Это мой рецепт.

128
00:05:38,620 --> 00:05:39,453
Спасибо что послушали.

129
00:05:39,453 --> 00:05:40,733
Следите за обновлениями. Пока-пока.
`