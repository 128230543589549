export const str_8_ru = `
1
00:00:00,029 --> 00:00:08,660
- Привет! Я получил вопрос от одного
моих подписчиков, которые нашли
противоречие в двух мыслях
которые я использую в своих презентациях.

4
00:00:08,660 --> 00:00:14,810
С одной стороны, я говорю, что программист
приходится работать всего несколько часов в день.

6
00:00:14,810 --> 00:00:16,890
И я это сказал.

7
00:00:16,890 --> 00:00:27,660
С другой стороны,
я говорю что хороший
программист это тот
кто увлечен этой
профессией, кто много работает,
кто в принципе не
закрыть ноутбук никогда.

11
00:00:27,660 --> 00:00:29,410
Итак, какое место я тут занимаю?

12
00:00:29,410 --> 00:00:30,243
Так что правильно?

13
00:00:30,243 --> 00:00:30,576
Что не верно?

14
00:00:30,576 --> 00:00:32,770
Программисты должны много работать?

15
00:00:32,770 --> 00:00:35,090
Или они должны просто
проводить несколько часов в день за работой?

16
00:00:35,090 --> 00:00:38,510
И тогда вопрос
от этого подписчика было,
"Что еще делать?"

19
00:00:38,510 --> 00:00:42,570
Если вы просто работаете
два-три часа в день
тогда что ты делаешь в
оставшееся время?

21
00:00:42,570 --> 00:00:48,100
На что программист, хороший программист,
кого я считаю хорошим, должен потратить время.

23
00:00:48,100 --> 00:01:03,150
Тут нет противоречия потому что я верю, что
работа это одно, а получать удовольствие от программирования
и работа с кодом, работа с техническими проблемы - это другое.

30
00:01:03,150 --> 00:01:04,040
Это то, чем я занимаюсь.

31
00:01:04,040 --> 00:01:07,040
Это то, что я думаю, имеет смысл для меня.

32
00:01:07,040 --> 00:01:29,930
Когда ты что-то делаешь
что доставляет тебе удовольствие,
когда ты чему-то учишься,
когда вы практикуете новые языки,
когда вы пробуете новые фреймворки,
при создании новых фреймворков,
когда читаешь интересные книги,
когда пишешь интересные книги,
когда вы пишете сообщения в блоге,
когда вы отвечаете на вопросы на stack overflow,
когда вы создаете некоторые проекты с открытым исходным кодом,
вот как вы развлекаетесь.

43
00:01:29,930 --> 00:01:31,330
Это твоя жизнь.

44
00:01:31,330 --> 00:01:34,740
Вот что ты делаешь
в идеале 24 часа в сутки.

45
00:01:34,740 --> 00:01:35,960
Конечно, вам нужно время, чтобы поспать.

46
00:01:35,960 --> 00:01:36,820
Тебе нужно время, чтобы поесть.

47
00:01:36,820 --> 00:01:38,790
Вам нужно время, чтобы провести
время с семьей.

48
00:01:38,790 --> 00:01:42,739
Но в идеале, в идеальном мире,
24 часа в сутки, я бы занимался этим.

50
00:01:42,739 --> 00:01:47,680
Я бы просто проводил все время
получая удовольствие от жизни
при работе с компьютерами.

52
00:01:47,680 --> 00:01:49,026
Это не то, что я называю работой.

53
00:01:49,026 --> 00:01:51,680
Это то, что я называю
инвестиции в будущее.

54
00:01:51,680 --> 00:01:57,620
Вот как я инвестирую
чтобы сделать себя лучше,
сделать себя как профессионала лучше.

56
00:01:57,620 --> 00:01:58,910
Это моя инвестиция.

57
00:01:58,910 --> 00:02:03,890
А надо то как-то жить,
потому что никто не заплатит мне за это,
потому что так я развлекаюсь.

60
00:02:03,890 --> 00:02:10,169
Некоторые люди играют в игры на компьютере,
они играют в Tanks,
они играют в World of Warcraft,
а я так не делаю.

63
00:02:10,169 --> 00:02:12,078
Я играю с кодом.

64
00:02:12,078 --> 00:02:13,660
Но никто за это платить не будет.

65
00:02:13,660 --> 00:02:14,493
Это просто весело.

66
00:02:14,493 --> 00:02:15,740
С другой стороны, я должен зарабатывать деньги.

67
00:02:15,740 --> 00:02:18,630
Так что я должен пойти куда-то и
сделать несколько проектов для кого-то.

68
00:02:18,630 --> 00:02:28,758
Поэтому мне нужно написать код для
кого-то, для какой-то компании
для какого-то работодателя или, может быть, какого-то
клиента или кого-то кто будет платить.

71
00:02:28,758 --> 00:02:30,660
И там не будет веселья, меньше веселья.

72
00:02:30,660 --> 00:02:35,970
Конечно, в идеале в идеальном мире,
я тоже буду там развлекаться, но гораздо меньше
в реальном мире, гораздо меньше удовольствия.

75
00:02:35,970 --> 00:02:42,437
И для большинства из вас,
вообще не будет веселья
или действительно мало удовольствия
потому что нужно делать что они скажут, что им нужно.

78
00:02:42,437 --> 00:02:45,760
И в большинстве случаев вам не
понравится требования бизнеса.

79
00:02:45,760 --> 00:02:47,790
Тебе не понравится что они делают.

80
00:02:47,790 --> 00:02:51,880
Им не понравится,
тебе не понравится
бизнес-кейсы и все такое.

82
00:02:51,880 --> 00:03:00,480
Но ты должен сделать это чтобы получить деньги,
для того, чтобы продолжать веселиться,
чтобы продолжать делать что-то интересное,
чтобы продолжить инвестирование в себя.

86
00:03:00,480 --> 00:03:05,099
Так что чем больше вы можете инвестировать в себя
тем лучшим профессионалом вы станете.

88
00:03:05,099 --> 00:03:09,407
И тем меньше времени
вам придется потратиться на зарабатывание денег.

90
00:03:09,407 --> 00:03:21,408
Итак, если вы очень хороший профессионал,
действительно лучший, лучший парень мирового класса,
кто знает что-то особенное
и кто может решить действительно сложные проблемы
может получить, я не знаю, 300 долларов в час.

95
00:03:21,408 --> 00:03:25,420
Тогда вы просто можете работать один-два часа в день
и этого вам будет достаточно.

97
00:03:25,420 --> 00:03:29,540
Иметь достаточно денег, чтобы
иметь возможность развлекаться.

98
00:03:29,540 --> 00:03:37,187
Так что чем дороже ты на рынке,
чем вы профессиональнее,
тем больше люди будут платить вам
за ваши 2, 3, 4 часа в день.

101
00:03:37,187 --> 00:03:39,730
А потом можно вернуться к веселью.

102
00:03:39,730 --> 00:03:47,270
И вместо того, чтобы играть в Tanks
или играть в World of Warcraft
вы потратите время на саморазвитие.

104
00:03:47,270 --> 00:03:52,020
Потому что каждый раз, когда ты веселишься,
каждый раз, когда ты делаешь что-то интересное,
ты становишься лучше.

107
00:03:52,020 --> 00:03:56,550
Ты не можешь стать лучше
просто выполняя рутинную работу
где люди платят за вас.

109
00:03:56,550 --> 00:03:58,370
Неважно, о чем вы думаете.

110
00:03:58,370 --> 00:04:01,223
Независимо от того, что люди, которые
наняли вас думают об этом.

111
00:04:01,527 --> 00:04:05,930
Вы становитесь лучше, делая что-то для удовольствия.

112
00:04:05,930 --> 00:04:07,747
Я верю в это.

113
00:04:07,747 --> 00:04:10,870
Потому что так ты учишься,
потому что так ты практикуешь новые вещи.

115
00:04:10,870 --> 00:04:19,377
Вот как вы сталкиваетесь с
вызовами, с которыми нам придется столкнуться более активно
чем тогда когда вы работаете с проектами за деньги.

117
00:04:19,377 --> 00:04:29,990
Так что должен быть баланс
между временем, которое вы проводите
на проектах, где
ты собираешь деньги
и время, которое вы тратите на проекты
куда вы вкладываете деньги или свое время.

122
00:04:29,990 --> 00:04:35,580
И в идеальном мире,
два, три часа в день здесь
и все ваше время там.

124
00:04:35,580 --> 00:04:47,080
А если много работать на работе, 
если вы работаете восемь часов
для твоего босса, восемь
часов для вашего клиента,
то у вас почти нет времени, потому что
вам нужно проводить время с семьей.

128
00:04:47,080 --> 00:04:51,180
Тебе нужно спать, тебе нужно есть.
Так что будет не хватать времени
по-настоящему инвестировать в себя.

131
00:04:51,180 --> 00:04:52,460
И быстро деградируешь.

132
00:04:52,460 --> 00:05:00,020
Вы станете просто обычным программистом, который,
в лучшем случае может поднять среднюю сумму денег,
получить среднюю сумму на рынке.

135
00:05:00,020 --> 00:05:12,093
Поэтому постарайтесь минимизировать количество
времени, которое вы тратите на своего босса,
которое вы тратите на проекты, 
где ты получаешь деньги
и уделять больше времени проектам
на которые вы тратите свое время и деньги.

141
00:05:12,093 --> 00:05:16,410
Но с другой стороны,
ты не можешь забрать все время на себя.

143
00:05:16,410 --> 00:05:22,620
Потому что в этом случае
ты перестаешь зарабатывать деньги и в конце концов
ты просто выйдешь из рынка.

146
00:05:22,620 --> 00:05:29,330
Итак, вы будете,
вы можете выйти из
рынока, потому что вы
перестанете понимать, что
клиентам может понадобиться от вас.

149
00:05:29,330 --> 00:05:30,580
Так что нужно остановиться изобретать.

150
00:05:30,580 --> 00:05:33,860
Вы можете начать изобретать
вещи, которые никому не нужны.

151
00:05:33,860 --> 00:05:38,250
Вы можете пойти учиться чему-то
полностью оторванному от реальности.

153
00:05:38,250 --> 00:05:45,740
Так что должно быть
баланс между инвестированием
в себя и работая на зарплату
50-50, не знаю - может это баланс.

156
00:05:45,740 --> 00:05:55,011
Но я всегда стараюсь
чтобы сделать эту часть больше,
инвестиционную часть,
потому что это веселее чем работать за деньги.

160
00:05:55,011 --> 00:05:59,800
Так что подумайте об этом,
это баланс - несколько
часов здесь и часы там.

162
00:05:59,800 --> 00:06:09,510
И вот как ты растешь,
так ты становишься лучше,
вот как ваш профиль растет,
вот как вы в конечном итоге получите себя
в ситуацию, когда один
час вашего времени стоит $300.

167
00:06:09,510 --> 00:06:14,530
В этом случае вы можете работать
несколько часов или, может быть,
несколько дней,
а затем отдохните несколько недель.

170
00:06:14,530 --> 00:06:25,220
Или, может быть, вы можете работать месяц, 
а потом отдыхать,
длительный отпуск, а не просто отпуск,
надолго развлекаться с компьютерами
может на год или около того.

173
00:06:25,220 --> 00:06:27,620
Это было бы идеальное положение.

174
00:06:27,620 --> 00:06:29,480
Не восемь часов каждый день в офисе.

175
00:06:29,480 --> 00:06:34,496
Это совершенно паршивая позиция
что полностью деградирует
вас и ведет вас в никуда.

177
00:06:34,496 --> 00:06:37,710
Надеюсь, я рассказал вам что-то интересное.

178
00:06:37,710 --> 00:06:39,010
Спасибо что слушали.

179
00:06:39,010 --> 00:06:39,843
Следите за обновлениями.

180
00:06:39,843 --> 00:06:40,676
Пока.
`