export const str_6_ru = `
1
00:00:00,000 --> 00:00:01,240
Вы смотрите Vagabrothers,

2
00:00:01,240 --> 00:00:03,100
а это Шри-Ланка

3
00:00:04,360 --> 00:00:07,920
Я Алекс. Я Марко, а мы братья Вага...

4
00:00:08,100 --> 00:00:13,660
братья, бродяги, и ваши путеводители для советов путешественникам, вдохновения и видеоблогов на YouTube.

5
00:00:15,070 --> 00:00:18,389
В этой серии мы открываем для себя лучшее, что есть в Шри-Ланке:

6
00:00:19,090 --> 00:00:20,619
древние города,

7
00:00:20,619 --> 00:00:21,970
потрясающая природа,

8
00:00:21,970 --> 00:00:24,420
богатая культура и вкусная еда.

9
00:00:29,360 --> 00:00:33,160
В первом эпизоде ​​мы исследуем столицу Коломбо.

10
00:00:33,160 --> 00:00:37,900
и делимся нашими важными советами о том, что вам нужно знать, прежде чем отправиться в Шри-Ланку.

11
00:00:38,520 --> 00:00:39,920
Что происходит у всех?

12
00:00:39,920 --> 00:00:41,100
Добро пожаловать обратно на канал.

13
00:00:41,100 --> 00:00:43,280
Сейчас мы в Коломбо на Шри-Ланке.

14
00:00:43,830 --> 00:00:47,689
собирается начать 10-дневное приключение по этому невероятному острову.

15
00:00:47,900 --> 00:00:50,060
Я взволнован, потому что я никогда не был на Шри-Ланке,

16
00:00:50,060 --> 00:00:54,529
но я слышал невероятные вещи о древних городах, красивых пляжах с отличным прибоем,

17
00:00:55,019 --> 00:00:59,482
восхитительная еда и невероятное сочетание культур, которые составляют этот остров.

18
00:00:59,482 --> 00:01:02,209
Мы собираемся начать все здесь, в Коломбо.

19
00:01:02,220 --> 00:01:08,420
Мы снова здесь с Карлосом Мэйсоном, нашим хорошим другом и оператором, который помогал нам в предыдущих поездках.

20
00:01:08,420 --> 00:01:13,040
Не говоря уже о том, что мы взяли с собой Кэрри Рэд, мою девушку, так что это будет очень веселое путешествие.

21
00:01:13,050 --> 00:01:16,219
Мы многое увидим в этой стране, но обо всем по порядку.

22
00:01:16,320 --> 00:01:19,160
мы начинаем здесь, в Коломбо, с экскурсии по городу.

23
00:01:19,160 --> 00:01:23,900
Прежде чем мы что-либо предпримем, вот что вам нужно знать перед поездкой на Шри-Ланку.

24
00:01:34,170 --> 00:01:38,390
Шри-Ланка, жемчужина Индийского океана, имеет богатую смесь культур и влияний,

25
00:01:39,090 --> 00:01:44,210
благодаря своему положению на морских торговых путях, по которым пряности доставлялись из Азии в Европу.

26
00:01:45,210 --> 00:01:52,309
Шри-Ланка была ранним центром буддизма, и по сей день многие паломники приезжают, чтобы отдать дань уважения ее многочисленным храмам.

27
00:01:52,460 --> 00:01:57,360
Наслаждайтесь древним искусством аюрведического целостного исцеления.

28
00:01:57,780 --> 00:02:00,980
Шри-Ланка является первоначальным источником корицы, которая привлекла

29
00:02:01,619 --> 00:02:05,160
иностранные торговцы с древних времен- от римлян до арабов

30
00:02:05,160 --> 00:02:09,559
и колонисты, такие как португальцы, голландцы и англичане, чьи церкви,

31
00:02:09,929 --> 00:02:12,619
крепости и плантации сохранились здесь и сегодня.

32
00:02:18,850 --> 00:02:24,389
Путешественникам из большинства стран нужна виза, но вы можете получить ее онлайн за 35 баксов, а оформление занимает всего пять минут.

33
00:02:24,390 --> 00:02:27,330
Только не оставляйте это на последний день. Дайте себе минимум 48 часов.

34
00:02:27,880 --> 00:02:29,909
Шри-Ланка — это довольно хорошее соотношение цены и качества.

35
00:02:30,580 --> 00:02:34,800
Бюджетные путешественники могут обойтись около пятидесяти долларов в день.

36
00:02:34,800 --> 00:02:39,480
ездить на местных автобусах и поездах и есть простые блюда, такие как рис и карри

37
00:02:39,640 --> 00:02:42,240
около двух-трех долларов за блюдо.

38
00:02:42,730 --> 00:02:45,573
Наем водителя значительно упростит передвижение,

39
00:02:45,573 --> 00:02:49,139
но это будет стоить вам около двухсот долларов в день на человека.

40
00:02:49,720 --> 00:02:52,800
Путешествие по Шри-Ланке часто связано с сезоном дождей.

41
00:02:52,870 --> 00:02:56,550
Так что, если одна сторона острова дождливая, другая часть может быть сухой.

42
00:02:56,550 --> 00:02:58,829
Лучшая погода – с декабря по апрель.

43
00:02:58,900 --> 00:03:01,489
Сезон дождей с мая по август,

44
00:03:01,489 --> 00:03:04,619
а мы снимаем этот сериал в межсезонье-

45
00:03:04,620 --> 00:03:07,336
Сентябрь и ноябрь, когда погода еще довольно хорошая,

46
00:03:07,336 --> 00:03:09,570
но вы часто можете получить тридцать процентов скидки в отелях.

47
00:03:10,450 --> 00:03:12,689
Наконец, не забудьте взять солнцезащитный крем,

48
00:03:13,150 --> 00:03:19,379
средства от комаров, головные уборы, а также дамы - не забудьте свои тампоны, потому что их здесь трудно достать.

49
00:03:20,020 --> 00:03:24,600
В целом погода теплая. Итак, футболки, шорты для парней, легкие платья для девушек.

50
00:03:24,600 --> 00:03:27,089
Делайте как местные жители и носите сандалии.

51
00:03:27,090 --> 00:03:30,560
Они идеально подходят для пляжа, храмов и даже во время ливня.

52
00:03:30,560 --> 00:03:34,890
но если вы планируете отправиться в поход, не забудьте взять с собой прочную обувь.

53
00:03:36,200 --> 00:03:38,880
У меня пиявка на ноге.

54
00:03:38,880 --> 00:03:41,280
Уход? Соленая вода.

55
00:03:43,709 --> 00:03:45,709
Ну вот.

56
00:03:45,989 --> 00:03:47,989
Да, братан, это моя кровь.

57
00:03:48,840 --> 00:03:50,810
Помните, что Шри-Ланка тропическая

58
00:03:50,810 --> 00:03:53,149
Так что это хорошая идея, чтобы взять с собой дождевик в любое время года.

59
00:03:53,340 --> 00:03:56,989
Если вы едете в горные районы, такие как Нувара-Элия,

60
00:03:57,200 --> 00:03:59,620
также возьмите с собой дополнительный слой, например куртку

61
00:03:59,620 --> 00:04:00,872
потому что по ночам бывает холодно

62
00:04:00,880 --> 00:04:03,160
и зонтик всегда хорошая идея.

63
00:04:03,160 --> 00:04:06,300
Не забудьте головной убор на случай солнца и дождя.

64
00:04:14,640 --> 00:04:19,899
Лучший способ не оскорблять местных жителей — проявлять уважение при посещении храма.

65
00:04:19,899 --> 00:04:20,779
Если вы дама,

66
00:04:20,796 --> 00:04:22,999
вы хотите скрыть как можно больше.

67
00:04:23,340 --> 00:04:24,870
Обязательно прикройте плечи.

68
00:04:24,870 --> 00:04:28,323
Наденьте брюки ниже колен или легкое платье.

69
00:04:28,323 --> 00:04:30,530
Вы также хотите прикрыть живот.

70
00:04:30,530 --> 00:04:34,280
И каждый должен снимать обувь в храме.

71
00:04:34,280 --> 00:04:36,440
Как мужчина, определенно хочу попробовать носить брюки.

72
00:04:36,570 --> 00:04:39,979
Также хорошо носить белое, но самое главное,

73
00:04:40,830 --> 00:04:45,440
Самое большое, чем можно оскорбить местных, — это сделать селфи с Буддой.

74
00:04:46,110 --> 00:04:50,223
Повернуться спиной к Будде — большой знак неуважения.

75
00:04:50,223 --> 00:04:54,520
Также не носите любые изображения Будды, футболки.

76
00:04:54,520 --> 00:04:57,180
Но даже если у тебя есть татуировка Будды,

77
00:04:57,440 --> 00:04:59,700
К сожалению, это третий страйк, и вы выбываете.

78
00:05:01,080 --> 00:05:03,520
Это то, что вам нужно знать, прежде чем идти.

79
00:05:03,520 --> 00:05:04,969
Вернемся к первому дню путешествия.

80
00:05:15,820 --> 00:05:18,060
Добро пожаловать в центр Коломбо.

81
00:05:18,060 --> 00:05:21,540
Первая остановка в нашем приключении здесь, в старой голландской больнице.

82
00:05:21,540 --> 00:05:24,324
На самом деле это самое старое здание во всем Коломбо.

83
00:05:24,324 --> 00:05:29,249
Первоначально это был госпиталь для солдат и офицеров голландской Ост-Индской компании.

84
00:05:29,500 --> 00:05:32,380
Вся эта местность известна как Форт,

85
00:05:32,380 --> 00:05:36,880
и это потому, что со времен арабов в 7 веке португальцы, британцы и голландцы

86
00:05:37,000 --> 00:05:40,159
все использовали это место как форт и торговый порт.

87
00:05:40,159 --> 00:05:43,260
И один из вопросов, который у меня есть за всю эту поездку:

88
00:05:43,540 --> 00:05:48,209
Зачем иностранцам заходить так далеко, чтобы обосноваться на этом острове?

89
00:05:48,210 --> 00:05:52,560
И какое наследие осталось от всего этого смешения культур?

90
00:05:52,560 --> 00:05:55,950
Первое место, где мы остановимся в этом приключении, это Министерство Краба.

91
00:05:55,950 --> 00:06:00,980
Это ресторан в этом комплексе, потому что недавно вся больница была переоборудована

92
00:06:00,980 --> 00:06:05,020
в торгово-ресторанный комплекс с множеством отличных бутиков и ресторанов,

93
00:06:05,020 --> 00:06:10,240
включая Министерство краба, которое входит в число 25 лучших ресторанов во всей Азии.

94
00:06:10,240 --> 00:06:12,240
Пойдем пообедаем.

95
00:06:23,600 --> 00:06:26,160
Министерство Краба - все о морепродуктах.

96
00:06:26,163 --> 00:06:30,009
Речь идет о крабах, но мы начнем с некоторых

97
00:06:30,560 --> 00:06:36,840
устрицы, приготовленные с двумя фирменными соусами и подаваемые на бите для крикета.

98
00:06:37,460 --> 00:06:40,940
Я люблю устрицы. Я очень взволнован этим, потому что

99
00:06:40,940 --> 00:06:43,920
у него есть острый соус и соевый соус, две вещи, которые я тоже люблю.

100
00:06:52,889 --> 00:06:55,019
Сохраняйте спокойствие и краб на.

101
00:07:13,390 --> 00:07:17,309
Первая главная остановка – храм Гангарам.

102
00:07:17,590 --> 00:07:22,019
который является буддийским храмом здесь, в Коломбо.

103
00:07:22,660 --> 00:07:28,920
Это довольно круто, потому что смешивает множество различных буддийских влияний со всего буддийского мира.

104
00:07:28,920 --> 00:07:32,911
Я думаю, некоторые из моих первых впечатлений здесь ... не могут не заметить сходство с Индией.

105
00:07:32,911 --> 00:07:34,199
Одно из самых больших отличий

106
00:07:34,200 --> 00:07:37,140
Разве что Шри-Ланка — страна с преобладающим буддизмом?

107
00:07:37,140 --> 00:07:41,640
в то время как в Индии преобладает индуистская страна.
 Буддизм действительно пришел из Индии,

108
00:07:41,640 --> 00:07:46,830
но это действительно укоренилось здесь, в Шри-Ланке, и страна была центром буддийского обучения на протяжении многих лет.

109
00:07:47,080 --> 00:07:51,719
тысячи лет, помогая распространять религию с первых дней ее существования по всему миру.

110
00:08:08,100 --> 00:08:11,730
Мне очень нравится первый день пребывания в стране, потому что это сочетание

111
00:08:12,480 --> 00:08:17,200
малое знание того, что у вас есть такого рода, привлекло вас сюда;

112
00:08:17,200 --> 00:08:19,800
любопытство, которое заставляло вас путешествовать в первую очередь,

113
00:08:20,140 --> 00:08:24,920
а также все новые ощущения и кусочки информации, которые вы получаете по ходу дела.

114
00:08:24,920 --> 00:08:28,820
Это как собрать кучу разных кусочков головоломки и попытаться собрать их все вместе.

115
00:08:41,589 --> 00:08:45,089
Довольно крутой способ начать наше путешествие по Шри-Ланке....

116
00:08:45,940 --> 00:08:51,419
прийти в такой красивый буддийский храм, как этот, но этот немного причудливый.

117
00:08:51,420 --> 00:08:55,182
Он немного другой, и в нем есть все эти разные буддийские артефакты.

118
00:08:55,182 --> 00:08:57,690
со всего буддийского мира,

119
00:08:57,900 --> 00:09:01,020
не говоря уже о множестве подаренных предметов

120
00:09:01,020 --> 00:09:03,918
как старинные камеры и старые автомобили.

121
00:09:03,920 --> 00:09:10,840
Так что это что-то вроде музея, школы, храма и очень уникального места.

122
00:09:11,020 --> 00:09:14,280
Буддийские безделушки, но все пожертвованные.

123
00:09:14,280 --> 00:09:17,600
Это действительно здорово, потому что буддизм по-разному выражен в разных странах.

124
00:09:17,600 --> 00:09:20,600
Практика буддизма здесь такая же, как и в Таиланде.

125
00:09:20,600 --> 00:09:22,600
как в Камбодже как в Тибете..

126
00:09:22,600 --> 00:09:24,820
отличается от Китая, Японии, Кореи.

127
00:09:24,820 --> 00:09:27,450
Вы можете увидеть множество примеров этих различных типов Будд.

128
00:09:27,450 --> 00:09:32,189
Это действительно здорово видеть, как одни и те же религии выражают немного по-разному, художественно,

129
00:09:32,709 --> 00:09:35,480
и духовно в этой части Азии.

130
00:09:35,480 --> 00:09:42,000
Кроме того, что я нахожу захватывающим в буддизме, так это то, что это духовное мировоззрение.

131
00:09:42,180 --> 00:09:46,520
Это вера в достижения

132
00:09:46,660 --> 00:09:53,280
Будда в достижении просветления и как пример для среднего человека, к которому нужно стремиться.

133
00:09:53,440 --> 00:09:57,419
Но что в этом крутого, так это то, что до того, как Будда стал Буддой,

134
00:09:57,670 --> 00:10:03,719
он был индусом, а индуистские боги есть во всех храмах, во всех буддийских храмах

135
00:10:03,720 --> 00:10:06,420
Так что довольно интересно зайти и увидеть Будду,

136
00:10:06,420 --> 00:10:10,278
но вы также видите Ганешу и некоторых других индуистских божеств.

137
00:10:10 280 --> 00:10:14 500
Просто делает вещи очень красочными, и искусство здесь невероятное.

138
00:10:14 600 --> 00:10:17 660
Все это просто заставляет меня волноваться о том, что произойдет через два дня.

139
00:10:17,940 --> 00:10:23,700
Мы попадаем в одно из мест зарождения буддизма в этой стране, древний город с удивительными храмами.

140
00:10:23,829 --> 00:10:25,555
Мы собираемся узнать намного больше,

141
00:10:25,555 --> 00:10:27,689
и это действительно только вершина айсберга.

142
00:10:27,689 --> 00:10:31,680
На этой ноте, я думаю, нам пора продолжать..

143
00:10:31,680 --> 00:10:34,100
отправляйтесь в путь и продолжайте исследовать Коломбо.

144
00:10:34,100 --> 00:10:36,100
Пойдем.

145
00:10:49,060 --> 00:10:55,440
Просто прогуляться по району рынка Петтах, действительно круто.

146
00:10:55,440 --> 00:11:00,660
Много чего происходит, и временами это будет своего рода сенсорной перегрузкой.

147
00:11:00,660 --> 00:11:05,320
А ведь это главный рыночный центр Коломбо.

148
00:11:05,320 --> 00:11:09,680
Это прямо рядом с портом. У вас есть мечети.

149
00:11:09,680 --> 00:11:12,280
У вас есть храмы, как индуистские, так и буддийские.

150
00:11:13,120 --> 00:11:15,720
На самом деле мы сейчас проходим мимо индуистского храма.

151
00:11:39,600 --> 00:11:43,760
Это супер повезло. Мы на вершине Красной мечети.

152
00:11:43,760 --> 00:11:46,100
Это самая большая мечеть на Шри-Ланке.

153
00:11:47,160 --> 00:11:53,060
На самом деле... минареты... вершины минаретов сделаны из граната.

154
00:11:53,060 --> 00:11:54,980
Несмотря на то, что здание было построено в 1908 г.

155
00:11:55,040 --> 00:11:59,720
она была построена и сейчас является самой большой мечетью в Шри-Ланке,

156
00:11:59,720 --> 00:12:02,318
не говоря уже о невероятно красивом.

157
00:12:02,318 --> 00:12:04,279
Нам очень повезло, что мы здесь.

158
00:12:04,830 --> 00:12:09,379
Люди в мечети очень дружелюбные, но это круто. Это другой опыт

159
00:12:09,560 --> 00:12:14,200
Около 8% населения Шри-Ланки исповедуют ислам.

160
00:12:14,200 --> 00:12:17,990
Это влияние, Ислам, было здесь очень давно.

161
00:12:17,990 --> 00:12:20,629
Мы смотрим на порт прямо здесь,

162
00:12:20,630 --> 00:12:24,800
а порт - это то, что приводило всех иностранцев на Шри-Ланку с древних времен.

163
00:12:24 900 --> 00:12:26 758
Арабы пришли сюда в седьмом веке,

164
00:12:26,758 --> 00:12:30,259
а затем за ними позже последовали португальцы, голландцы и англичане.

165
00:12:30,420 --> 00:12:34,639
Но действительно здорово видеть, как эта культура выжила.

166
00:12:34,640 --> 00:12:38,780
Это всего лишь одна часть всего разнообразия, которое делает Шри-Ланку таким интересным местом.

167
00:12:38,940 --> 00:12:42,049
и это архитектурно красивое место и

168
00:12:42,630 --> 00:12:47,210
также отличное место, чтобы увидеть, где этот город начался, прямо здесь, в порту.

169
00:13:45,700 --> 00:13:47,560
Как прошел первый день, Карлос?

170
00:13:47,560 --> 00:13:48,472
День первый классный.

171
00:13:48,472 --> 00:13:50,700
Он во многом похож на Индию,

172
00:13:51,399 --> 00:13:53,399
но это гораздо более расслабляющий,

173
00:13:53,529 --> 00:13:57,660
меньше людей, гораздо более влажно, чем в Раджастане,

174
00:13:57,660 --> 00:13:58,739
но это красивый город.

175
00:13:59,319 --> 00:14:03,660
С нетерпением жду приключений в дикой местности и выхода из города.

176
00:14:03,660 --> 00:14:04,619
Это будет хорошо.

177
00:14:05,379 --> 00:14:07,120
Первые впечатления:

178
00:14:07,120 --> 00:14:09,800
Погода напоминает мне южный Таиланд.

179
00:14:09,800 --> 00:14:13,620
Я люблю это. Люби меня немного влаги.

180
00:14:13,840 --> 00:14:15,900
Еда... отличная.

181
00:14:15,910 --> 00:14:18,260
Моя цель на оставшуюся часть поездки?

182
00:14:18,260 --> 00:14:20,790
Мне нужно увидеть слона. Этого еще не произошло.

183
00:14:21,220 --> 00:14:23,910
Судя по тому, что я слышал, это произойдет завтра,

184
00:14:23,910 --> 00:14:25,910
чему я очень рад.

185
00:14:43,900 --> 00:14:47,200
Я очень приятно удивлен Коломбо.

186
00:14:47,200 --> 00:14:49,820
За то, что столица Шри-Ланки,

187
00:14:50,120 --> 00:14:53,920
Я ожидал, что это будет немного более беспокойно. Это не.

188
00:14:53,920 --> 00:14:56,940
Я ожидал, что будет жарче. Это не так.

189
00:14:57,460 --> 00:15:00,360
Мне нравится тот факт, что мы заканчиваем его прямо рядом с пляжем.

190
00:15:00,490 --> 00:15:06,750
В этой стране всего 22 миллиона человек, а в Индии их более миллиарда.

191
00:15:06,760 --> 00:15:12,989
Там почти то же ощущение, но гораздо меньше людей, меньше жары и немного влажно, но не слишком жарко.

192
00:15:14,020 --> 00:15:16,310
Лично я думаю, что это действительно крутое место, чтобы закончить.

193
00:15:16,310 --> 00:15:18,380
Мы смотрим на порт прямо здесь.

194
00:15:18,380 --> 00:15:22,460
Все это поле было создано голландцами для размещения пушек,

195
00:15:22,460 --> 00:15:24,509
и теперь они осваивают землю здесь

196
00:15:24,850 --> 00:15:27,480
с китайскими инвестициями, чтобы построить совершенно новый супергород.

197
00:15:27,480 --> 00:15:31,500
И это может выглядеть совсем иначе, чем сейчас, через пять или десять лет.

198
00:15:31,500 --> 00:15:33,689
Да, полностью... Шри-Ланка меняется.

199
00:15:34,120 --> 00:15:36,060
Коломбо меняется.

200
00:15:36,060 --> 00:15:40,379
У них здесь мир уже почти десятилетие, и я думаю, что

201
00:15:40,780 --> 00:15:43,140
судя по людям, с которыми мы говорили сегодня,

202
00:15:43,140 --> 00:15:44,970
дела действительно идут в гору.

203
00:15:45,640 --> 00:15:49,340
Шри-Ланка – место, куда стоит приехать.

204
00:15:49,340 --> 00:15:51,060
Я в восторге. Я не могу дождаться завтра

205
00:15:51,240 --> 00:15:55,080
Нам еще многое предстоит исследовать, многое увидеть,

206
00:15:55,080 --> 00:15:56,520
и мы берем вас всех с собой.

207
00:15:56,560 --> 00:16:00,960
Это побережье. Но отсюда мы идем вглубь страны.

208
00:16:00,960 --> 00:16:02,339
Мы посетим много крутых мест.

209
00:16:02,340 --> 00:16:06,090
Если вы только что нашли это видео, обязательно подпишитесь на Vagabrothers,

210
00:16:06,550 --> 00:16:12,240
лайкните это видео, поделитесь с друзьями-путешественниками и следите за обновлениями этой серии на Шри-Ланке.

211
00:16:12,240 --> 00:16:17,000
А пока помните, оставайтесь любопытными, продолжайте исследовать, и мы увидим вас в дороге.

212
00:16:17,000 --> 00:16:18,260
Мир.

`