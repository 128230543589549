export const str_5_ru = `
1
00:00:53,870 --> 00:00:56,770
Ладно, ребята, это первый день здесь, в Индии.

2
00:00:56,770 --> 00:01:01,900
Это мой первый полный день в Индии, и это место невероятно, если не сказать больше.

3
00:01:03,020 --> 00:01:08,050
Мы собираемся начать этот тур по Раджастану здесь, в Джайпуре, и что у нас есть сегодня?

4
00:01:08,300 --> 00:01:11,030
Мы собираемся отправиться в недельный тур по Раджастану.

5
00:01:11,030 --> 00:01:13,569
Раджастхан — один из самых колоритных штатов Индии.

6
00:01:14,000 --> 00:01:18,699
Раджастхан в основном означает Земля Раджаса или Земля Королей, потому что есть масса разных

7
00:01:18,800 --> 00:01:21,939
княжества или маленькие королевства, составляющие все это государство.

8
00:01:22,400 --> 00:01:26,600
Итак, вы можете знать или не знать, я жил в Индии 9 лет назад,

9
00:01:26,780 --> 00:01:28,440
и я приехал через Раджастхан, когда я закончил свою работу.

10
00:01:28,440 --> 00:01:33,000
Привет, это я, и я хочу показать вам свой отель здесь, в Удайпуре.

11
00:01:33,180 --> 00:01:37,500
где я плачу два доллара и пятьдесят центов за ночь

12
00:01:37,840 --> 00:01:40,080
Так что проверьте это. Я был во многих местах, которые

13
00:01:40,090 --> 00:01:42,520
мы собираемся поехать и на легендарный фестиваль верблюдов в Пушкаре

14
00:01:42,619 --> 00:01:46,359
Так что я очень рад показать это место вам и всем вам, ребята.

15
00:01:46,430 --> 00:01:50,979
Мы собираемся сделать целую серию о Раджастане, самом колоритном штате Индии.

16
00:01:50,979 --> 00:01:55,460
Мы посетим три основных города этой серии, начиная с Джайпура, Розового города,

17
00:01:55,460 --> 00:01:59,100
Удайпур, Белый город и Джодхпур, Голубой город.

18
00:01:59,220 --> 00:02:03,820
Завершим все на легендарном Фестивале верблюдов в Пушкаре.

19
00:02:03,820 --> 00:02:06,700
Ждать. Фестиваль верблюдов? Да.

20
00:02:06,700 --> 00:02:09,400
Фестиваль верблюдов в Пушкаре станет священной церемонией,

21
00:02:09,539 --> 00:02:11,240
также церемония торговли верблюдами.

22
00:02:11,240 --> 00:02:12,160
Это чертовски интересно.

23
00:02:12,160 --> 00:02:15,910
Это будет праздник для глаз. Так что следите за всем этим.

24
00:02:15,910 --> 00:02:17,780
Во время этой поездки мы будем снимать фильмы виртуальной реальности.

25
00:02:17,780 --> 00:02:19,840
Так что будьте готовы к ним.

26
00:02:19,840 --> 00:02:21,520
Одним из них будет Гранд Тур по Раджастану,

27
00:02:21,700 --> 00:02:26,100
а другой будет ярмаркой верблюдов с тележкой на 360 градусов.

28
00:02:26,320 --> 00:02:29,380
К нам присоединился Карлос Мейсон, талантливый кинематографист, который едет с нами.

29
00:02:29,380 --> 00:02:32,560
в Перу, в Южную Корею и помогает нам на востоке Эдема.

30
00:02:32,680 --> 00:02:35,180
К нам также присоединилась Селин Трикарт.

31
00:02:35,180 --> 00:02:36,720
Она специалист по виртуальной реальности, которая будет помогать нам с двумя фильмами в виртуальной реальности.

32
00:02:36,780 --> 00:02:42,900
Мы начинаем здесь, в форте Амер в Джайпуре, так что есть что исследовать. Давайте начнем. Пойдем.

33
00:02:56,860 --> 00:02:58,860
Мы размещены на стене.

34
00:02:59,349 --> 00:03:02,279
Просто пытаюсь сделать хороший установочный кадр, но...

35
00:03:03,489 --> 00:03:05,579
Какое сумасшедшее место. Столько всего происходит.

36
00:03:06,940 --> 00:03:11,400
Там буквально процессия слонов

37
00:03:11,520 --> 00:03:15,360
несли людей по крепостным валам в крепость.

38
00:03:30,040 --> 00:03:31,700
Завести новых друзей, Марко?

39
00:03:31,700 --> 00:03:36,630
Находить новых друзей. Вы девушки из Раджастхана? Нет

40
00:03:36,630 --> 00:03:41,060
Как сказать сыр на хинди? Сыр. Панир.

41
00:03:58,140 --> 00:04:01,280
Так что, по-видимому, здесь, в Индии, дело обстоит именно так;

42
00:04:01,280 --> 00:04:03,980
людям просто нравится делать с тобой селфи

43
00:04:04,060 --> 00:04:08,040
Если ты с запада, то ты просто хороший человек, с которым можно сделать селфи.

44
00:04:12,010 --> 00:04:14,010
Отличная работа

45
00:04:43,460 --> 00:04:47,280
Итак, мы на вершине форта Джайгарх.

46
00:04:47,380 --> 00:04:52,440
В яблочко. Гарх означает форт, а Джай назван в честь Джая Сингха, который был одним из здешних махараджей.

47
00:04:52,450 --> 00:04:56,499
Махараджа — это слово на хинди, означающее «великий король и

48
00:04:57,200 --> 00:05:00,430
Раджастхан, Земля королей, потому что, как и это место,

49
00:05:00,470 --> 00:05:06,400
существует дюжина или около того королевств, которыми управляли независимо друг от друга на протяжении сотен лет.

50
00:05:06,400 --> 00:05:12,009
В настоящее время это отличное место, чтобы немного подняться и по-настоящему насладиться пейзажем, но это невероятно.

51
00:05:12,010 --> 00:05:18,849
Есть эти стены, которые просто обнимают холмы, почти как маленькая миниатюрная версия Великой Китайской стены.

52
00:05:19,100 --> 00:05:24,620
Невероятное, невероятное место, где можно сориентироваться в первый день в Раджастане.

53
00:05:25,000 --> 00:05:27,920
Это правда, что вы говорите о Великой Китайской стене, потому что эти стены,

54
00:05:27,920 --> 00:05:30,140
как Великая Китайская стена, были построены для защиты от вторжения армий.

55
00:05:30,340 --> 00:05:32,540
Здесь прошли греки;

56
00:05:32,540 --> 00:05:38,560
Здесь прошел Чингисхан, и Моголы, мусульманская империя из Средней Азии, все они прошли здесь.

57
00:05:38,560 --> 00:05:43,780
И им сопротивлялись на этих самых стенах. Довольно невероятное место. Так много истории.

58
00:05:44,210 --> 00:05:50,409
Честно говоря, пытаться инкапсулировать историю этого места в видео на YouTube — непростая задача.

59
00:05:50,420 --> 00:05:55,700
Мы сделаем все возможное. Так что оставайтесь с нами, пока мы продолжаем исследовать Раджастхан.

60
00:06:11,980 --> 00:06:15,100
Довольно интересная информация здесь.

61
00:06:15,100 --> 00:06:18,460
В те времена, когда здесь жили раджи,

62
00:06:18,920 --> 00:06:23,349
когда были плохие люди, когда они делали что-то не так, их брали сюда,

63
00:06:23,350 --> 00:06:27,060
и эта маленькая яма позади меня была полна воды,

64
00:06:27,060 --> 00:06:29,400
и в той воде были крокодилы.

65
00:06:29,440 --> 00:06:34,060
Очень похоже на злодея Джеймса Бонда.

66
00:06:34,060 --> 00:06:37,500
они бросали бы людей, приговоренных к смерти, в пруд с крокодилами.

67
00:06:38,120 --> 00:06:41,890
У них не было тунцов с лазерными лучами, но были крокодилы.

68
00:06:42,440 --> 00:06:44,800
Это очень хорошо спроектированная крепость.

69
00:06:44,800 --> 00:06:46,690
Вы можете видеть здесь ..это для стрел.

70
00:06:47,270 --> 00:06:51,200
Это прямо здесь для сжигания горячего масла

71
00:06:51,200 --> 00:06:52,900
так что вы могли вылить его на любого, кто попытается взобраться на форт.

72
00:06:52,900 --> 00:06:59,529
А потом большие отверстия, это для пушек, включая самую большую пушку на колесах в мире, которая находится прямо здесь.

73
00:06:59,840 --> 00:07:04,419
Итак, все эти крепости и множество зданий, которые мы увидим в сериале в Раджастане.

74
00:07:04,450 --> 00:07:06,780
Мы построены в 14 и 1500-х годах.

75
00:07:06,780 --> 00:07:09,009
Это было время, когда Европа действительно только-только выходила из темных веков.

76
00:07:09,010 --> 00:07:14,800
Но здесь, в Индии, эти цивилизации достигли высот, известных во всем мире.

77
00:07:25,279 --> 00:07:27,540
Итак, теперь вы оцените самую большую в мире пушку.

78
00:07:27,540 --> 00:07:32,180
Просто посмотри на эти диски.. вращение, вращение, вращение

79
00:07:32,180 --> 00:07:33,720
Они не крутятся сейчас. Они не крутятся.

80
00:07:33,720 --> 00:07:36,480
Эта вещь огромна. Видимо, когда стреляли,

81
00:07:36,480 --> 00:07:43,760
он разрушил город, на который был нацелен, и парня, который стрелял в него, отбросило в ответ эхом.

82
00:07:43,820 --> 00:07:46,520
он упал со спины насмерть.

83
00:07:46,520 --> 00:07:48,680
Это то, что мы бы назвали отдачей.

84
00:07:48,680 --> 00:07:52,180
Селин, у тебя есть аудитория. Да, я знаю.

85
00:07:52,180 --> 00:07:54,200
Я никогда в жизни не был так знаменит. Это потрясающе, не так ли?

86
00:07:54,200 --> 00:07:56,800
Я не хочу когда-либо покидать Индию

87
00:08:18,000 --> 00:08:21,940
Произошла небольшая автомобильная авария

88
00:08:22,420 --> 00:08:24,420
Наш водитель случайно

89
00:08:24,580 --> 00:08:26,720
врезался в маленькую стену.

90
00:08:26,720 --> 00:08:29,580
Мы пешком в городе Амер.

91
00:08:30,430 --> 00:08:32,430
Это как старый, раньше был старый Джайпур,

92
00:08:32,440 --> 00:08:36,640
но сейчас это просто вид на окраине.

93
00:08:36,640 --> 00:08:38,900
Это как в пригороде на самом деле

94
00:08:38,900 --> 00:08:41,140
но мы проходим мимо всяких храмов и

95
00:08:42,760 --> 00:08:44,760
вид блуждания по этим маленьким закоулкам.

96
00:09:00,820 --> 00:09:02,620
Так что это довольно круто.

97
00:09:02,620 --> 00:09:07,740
Прямо сейчас мы находимся на хорошем этапе, и большая часть Индии похожа на тропики или субтропики.

98
00:09:07,880 --> 00:09:12,669
Но здесь, в Раджастане, мы были на краю пустыни Тар или Великой Индийской пустыни.

99
00:09:12,740 --> 00:09:16,090
это двести тысяч квадратных километров и

100
00:09:16,460 --> 00:09:21,960
Чтобы в основном сохранить воду, которую они получают во время сезона дождей, они построили эти большие ступенчатые колодцы.

101
00:09:22,080 --> 00:09:25,580
Внизу есть вода.

102
00:09:25,760 --> 00:09:30,970
Они были построены пару сотен лет назад, но отличное место для фото

103
00:09:30,970 --> 00:09:34,540
И это похоже на какой-то сюрреалистический вид современного искусства.

104
00:09:44,649 --> 00:09:47,806
Это мой первый день в Индии, и я снова влюбляюсь.

105
00:09:47,806 --> 00:09:50,960
Вы должны любить эту страну. Просто столько счастья.

106
00:09:51,260 --> 00:09:53,380
Есть люди; есть запахи; есть звуки.

107
00:09:53,385 --> 00:09:55,979
Это никогда не останавливается, и это такая продуктивная страна.

108
00:09:55,980 --> 00:10:00,029
Повсюду растут деревья. Есть обезьяны. По улицам бродят животные и коровы.

109
00:10:00,339 --> 00:10:03,749
В первый день много хлопот, но если ты сможешь замедлиться и оценить это,

110
00:10:03,750 --> 00:10:07,260
Вы действительно можете влюбиться в это место, как и я.

111
00:10:28,699 --> 00:10:31,740
Мы в центр Джайпура:

112
00:10:31,740 --> 00:10:34,329
безумие продолжается, как всегда здесь, в Индии.

113
00:10:34,969 --> 00:10:38,709
Мы попробуем сделать последний снимок виртуальной реальности на сегодня и

114
00:10:39,319 --> 00:10:45,069
Это в месте под названием Хава-Махал, что действительно весело.

115
00:10:45,069 --> 00:10:47,860
Хотя это хаотично, Джайпур - первый запланированный город Индии.

116
00:10:47,860 --> 00:10:50,079
Он был построен в 1700 году, когда здесь был город.

117
00:10:50,079 --> 00:10:53,408
И все это изложено в формировании сетки. Каждый маленький прямоугольный

118
00:10:54,199 --> 00:10:58,028
block — это нечто странное, так что это просто гигантский рынок.

119
00:11:09,710 --> 00:11:11,280
Этот парень потрясающий.

120
00:11:11 280 --> 00:11:14 580
Одна из лучших вещей в Индии — это масала-чай.

121
00:11:14,580 --> 00:11:18,740
Чай на хинди означает чай, и здесь он очень вкусный.

122
00:11:18,740 --> 00:11:22,219
Это очень мило; он приправлен, и его подают прямо здесь, на улице.

123
00:11:22,220 --> 00:11:23,468
И знаешь что, Марк?

124
00:11:23,468 --> 00:11:26,200
Это придает совершенно новый смысл грязному чаю. О, Боже.

125
00:11:26,200 --> 00:11:28,280
Взгляните на место позади нас.

126
00:11:28,280 --> 00:11:32,380
Он подлинный, насколько это возможно. Это чай, и он грязный, и мы его пьем.

127
00:11:32,390 --> 00:11:35,020
Это вкусно, так что парень, который продает, зовется Чай Валлах.

128
00:11:35,020 --> 00:11:39,980
а здесь чай доходит до пяти рупий, что составляет всего пару центов. это потрясающе

129
00:11:57,260 --> 00:11:59,440
Итак, планы немного изменились.

130
00:11:59,660 --> 00:12:02,580
Свет действительно не так хорош на нем прямо сейчас,

131
00:12:02,580 --> 00:12:05,079
так что мы собираемся вернуться в городской дворец

132
00:12:05,720 --> 00:12:08,780
Там немного лучше свет.

133
00:12:08,780 --> 00:12:12,730
Мы сделаем небольшой ролик об Одиссее, а затем сделаем несколько фотографий.

134
00:12:35,720 --> 00:12:42,639
Владелец слона только что вышел и буквально берет здесь выпивку.

135
00:12:42,639 --> 00:12:44,639
Этот большой красивый слон.

136
00:13:03,020 --> 00:13:06,009
Ладно, это официально... Индия - самая крутая страна на земле.

137
00:13:25,950 --> 00:13:27,950
Итак, позади нас находится Хава-Махал.

138
00:13:28,350 --> 00:13:31,580
Махал означает дворец на хинди, и это переводится как Дворец Ветра.

139
00:13:31,860 --> 00:13:34,339
Итак, в тот день, когда город был построен

140
00:13:34,340 --> 00:13:38,040
это был женский район города, и вот этот район был спроектирован

141
00:13:38,040 --> 00:13:40,560
чтобы женщины могли смотреть на улицу, но не быть замеченными.

142
00:13:41,220 --> 00:13:44,300
Махараджи в то время были индусами, но в то время индусы тоже

143
00:13:44,630 --> 00:13:48,320
нужно было скрыть от всеобщего обозрения, так что это был способ сделать это.

144
00:13:48,320 --> 00:13:50,270
Он был окрашен в розовый цвет, что является здесь символом гостеприимства.

145
00:13:50,400 --> 00:13:55,560
в честь принца Уэльского, приехавшего сюда, чтобы сыграть в поло с махараджей.

146
00:14:25,840 --> 00:14:28,940
О мой Бог.

147
00:14:28,940 --> 00:14:33,219
Это был дикий день, первый день в Индии. Безумие

148
00:14:34,070 --> 00:14:37,899
Красивое красивое безумие, и я не могу дождаться завтра

149
00:14:38,390 --> 00:14:40,400
Я бы сказал, я немного завидую.

150
00:14:40,400 --> 00:14:43,599
Мой первый день в Индии через две недели после окончания колледжа

151
00:14:44,240 --> 00:14:46,779
был самым сумасшедшим опытом в моей жизни.

152
00:14:46,780 --> 00:14:54,460
Я думаю, что нет такого количества культурного шока, как твой первый день в Индии. это несравнимо.

153
00:14:54,460 --> 00:14:57,399
Я немного завидовал тому, что вы проходите через это сегодня, потому что я помню, как сильно это меня изменило.

154
00:14:57,400 --> 00:14:59,890
Я думаю, это клише сказать, что поездка в Индию изменила вашу жизнь.

155
00:14:59,890 --> 00:15:03,069
Но это только первый день целой многонедельной поездки.

156
00:15:03,070 --> 00:15:07,960
Я был очень рад, что ты был моим гидом по Индии, братан, и просто мог ориентироваться

157
00:15:09,080 --> 00:15:14,559
хаос, который разворачивается снаружи этого маленького святилища, нашего отеля.

158
00:15:14,720 --> 00:15:20,980
Но это был невероятный день, и я действительно не могу дождаться новых исследований завтра.

159
00:15:20,980 --> 00:15:23,120
А пока, ребята, если вам понравилось это видео, ставьте палец вверх,

160
00:15:23,120 --> 00:15:24,909
поделитесь с друзьями и подпишитесь на Vagabrothers.

161
00:15:25,340 --> 00:15:26,840
Включить уведомления

162
00:15:26,840 --> 00:15:28,840
А пока оставайтесь любопытными,

163
00:15:28,840 --> 00:15:30,490
продолжайте исследовать, и мы увидим вас, ребята, на дороге.

164
00:15:32,060 --> 00:15:34,060
Намасте.

`