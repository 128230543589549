export const str_1_ru = `
1
00:00:08,160 --> 00:00:09,560
Солнечная система.

2
00:00:13,940 --> 00:00:15,000
Солнце.

3
00:00:16,620 --> 00:00:20,180
Солнце очень большое и горячее.

4
00:00:22,140 --> 00:00:23,100
Солнце.

5
00:00:27,660 --> 00:00:36,260
Есть Меркурий, Венера, Земля, Марс, Юпитер, Сатурн, Уран и Нептун.

6
00:00:36,980 --> 00:00:39,860
Они вращаются вокруг Солнца.

7
00:00:41,840 --> 00:00:44,460
Солнечная система.

8
00:00:45,180 --> 00:00:48,340
Это наша Солнечная система.

9
00:00:49,800 --> 00:00:51,480
Солнечная система.

10
00:00:57,400 --> 00:01:08,980
Меркурий, Венера, Земля, Марс, Юпитер, Сатурн, Уран, Нептун и Солнце.

11
00:01:11,240 --> 00:01:12,780
Меркурий.

12
00:01:13,820 --> 00:01:18,420
Меркурий ближайшая к Солнцу планета.

13
00:01:20,640 --> 00:01:21,820
Венера.

14
00:01:22,900 --> 00:01:27,500
Венера самая яркая планета на небе.

15
00:01:29,720 --> 00:01:30,780
Земля.

16
00:01:31,820 --> 00:01:34,420
Земля наша планета.

17
00:01:36,520 --> 00:01:37,800
Марс.

18
00:01:38,660 --> 00:01:41,680
Марс красная планета.

19
00:01:43,920 --> 00:01:45,240
Юпитер.

20
00:01:46,240 --> 00:01:49,880
Юпитер самая большая планета.

21
00:01:51,780 --> 00:01:53,100
Сатурн.

22
00:01:54,380 --> 00:01:58,160
Сатурн планета с кольцами.

23
00:02:00,020 --> 00:02:01,640
Уран.

24
00:02:03,000 --> 00:02:07,060
Уран очень холодный и облачный.

25
00:02:08,880 --> 00:02:10,500
Нептун.

26
00:02:11,760 --> 00:02:14,620
Нептун состоит из газа.

27
00:02:16,600 --> 00:02:27,580
Солнце, Меркурий, Венера, Земля, Марс, Юпитер, Сатурн, Уран, Нептун

28
00:02:29,340 --> 00:02:32,440
Это наша солнечная система.
`