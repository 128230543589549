export const str_4_ru = `
1
00:00:00,030 --> 00:00:01,660
Как дела? Вы смотрите Vagabrothers

2
00:00:01,660 --> 00:00:03,160
и это Перу.

3
00:00:24,680 --> 00:00:26,180
Что здесь происходит?

4
00:00:26,180 --> 00:00:28,120
С возвращением на наш канал.

5
00:00:28,120 --> 00:00:29,860
Прямо сейчас мы в городе Лима, Перу.

6
00:00:29,860 --> 00:00:32,400
Это наша первая поездка в эту страну.

7
00:00:32,400 --> 00:00:34,630
и мы очень рады быть здесь.

8
00:00:34,760 --> 00:00:36,430
У нас есть много, что рассказать вам.

9
00:00:36,430 --> 00:00:40,900
Мы в Перу, потому что мы являемся частью программы YouTube и делаем четыре видеоролика виртуальной реальности,

10
00:00:40,900 --> 00:00:43,510
также мы проведем тур виртуальной реальности на Мачу-Пикчу.

11
00:00:43,760 --> 00:00:46,749
Но мы не хотели пробираться сюда в течении пары дней.

12
00:00:46,750 --> 00:00:50,500
Так что мы прибыли немного раньше, чтобы исследовать города Куско и Лима.

13
00:00:50,500 --> 00:00:51,790
Чтобы лучше понять опыт,

14
00:00:51,790 --> 00:00:55,880
мы приглашаем нашего друга и коллегу Карлоса Мейсона,

15
00:00:55,880 --> 00:00:58,359
кинематографист, отвечавший за East of Eden и нашего ментора виртуальной

16
00:00:58,730 --> 00:01:02,540
реальности, DJ, помогающий нам запечатлеть  Мачу-Пикчу,

17
00:01:02,540 --> 00:01:04,440
лучшим для нас способом.

18
00:01:04,560 --> 00:01:05,379
Это будет невероятное приключение.

19
00:01:05,379 --> 00:01:06,180
Мы хорошо покушаем.

20
00:01:06,180 --> 00:01:07,620
Мы встретим наших друзей из Перу.

21
00:01:07,620 --> 00:01:09,800
Мы будем исследовать культуру и историю... всё это,

22
00:01:09,820 --> 00:01:11,060
но для начала

23
00:01:11,060 --> 00:01:13,560
мы расскажем вам о Перу.

24
00:01:20,020 --> 00:01:23,460
Перу - страна контрастов, как древних, так и современных,

25
00:01:24,079 --> 00:01:25,660
коренные и европейские.

26
00:01:25,660 --> 00:01:27,420
С более чем 90 микроклиматов,

27
00:01:27,420 --> 00:01:29,949
это одно из самых биоразнообразных мест на земле.

28
00:01:33,479 --> 00:01:35,360
Побережье имеет прекрасный прибой.

29
00:01:35,360 --> 00:01:38,560
Анды - это самый большой горный хребет в мире после Гималаев.

30
00:01:38,560 --> 00:01:40,669
Озеро Титикака - самое высокое судоходное

31
00:01:40,670 --> 00:01:43,040
озеро в мире и самое большое в Южной Америке.

32
00:01:43,040 --> 00:01:43,909
Кроме этого, оно довольно забавно звучит.

33
00:01:43,909 --> 00:01:45,030
Титикака.

34
00:01:45,030 --> 00:01:48,600
Перу был домом для империи инков, известной как Тавантинсуйю

35
00:01:48,600 --> 00:01:50,629
или империя четырех кардинальных точек.

36
00:01:50,970 --> 00:01:57,559
Это золотая эра длилась всего около ста лет, пока испанцы не прибыли в 1532 году и Писарро

37
00:01:57,960 --> 00:02:03,739
захватил, требовал выкуп и убил Императора Атауальпа, прежде чем обыскать их столицу Куско.

38
00:02:04,500 --> 00:02:07,900
После этого Писарро перенес столицу в город Лиму, которую он назвал "городом королей".

39
00:02:07,900 --> 00:02:11,089
Он стал столицей и самым важным городом в Вице-Королевстве Перу,

40
00:02:11,250 --> 00:02:15,619
которая фактически была всей испанской Латинской Америкой.

41
00:02:15,720 --> 00:02:18,160
Сегодня Лима все еще является столицей.

42
00:02:18,160 --> 00:02:21,340
В нем проживает около 32 миллионов жителей Перу,

43
00:02:21,340 --> 00:02:23,780
и это самая сухая столица на земле,

44
00:02:23,940 --> 00:02:25,340
после Каира.

45
00:02:26,640 --> 00:02:28,980
В Перу больше шаманов, чем в каких-либо других странах мира,

46
00:02:28,980 --> 00:02:33,470
благодаря Амазонии, где местный наркотик Аяуаска использовался как лекарство в течение тысяч лет.

47
00:02:33,900 --> 00:02:38,160
Тем временем в Андах, местные жители использовали кокаиновые листья, основной ингредиент для кокаина,

48
00:02:38,160 --> 00:02:39,950
для борьбы с голодом и высотной болезнью.

49
00:02:41,040 --> 00:02:45,000
Не беспокойтесь. Это не вызывает привыкания, но делает ваш язык онемевшим.

50
00:02:45,000 --> 00:02:51,200
На сегодняшний день 45% перуанцев являются коренными жителями, что делает Перу одной из самых коренных стран Южной Америки.

51
00:02:52,260 --> 00:02:53,940
15% - Креолов,

52
00:02:53,940 --> 00:02:58,309
перуанцев, родившихся в Испании и 37% метисов или смешанных национальностей.

53
00:02:58,799 --> 00:03:01,969
Перу также было домом для небольшого, но активного японского сообщества,

54
00:03:01,970 --> 00:03:05,020
которое пришло сюда в 1800 годах, чтобы избежать войны сёгунов

55
00:03:05,020 --> 00:03:09,229
и оставили свой след через перуанскую японскую кухню , известную как Nikkei.

56
00:03:09,750 --> 00:03:12,640
Перуанская еда оказала огромное влияние на мировую кухню.

57
00:03:12,640 --> 00:03:14,209
Ирладия не имела бы картошки,

58
00:03:14,640 --> 00:03:16,240
Америка не имела бы кукурузы,

59
00:03:16,240 --> 00:03:18,979
и не было бы индийских карри без чили из Перу,

60
00:03:19,170 --> 00:03:22,394
все они вывезены за границу португальскими и испанскими торговцами.

61
00:03:22,400 --> 00:03:26,260
По какой-то причине национальное блюдо в Перу не стало модным.

62
00:03:26,280 --> 00:03:27,300
На английском мы называем это морской свинкой

63
00:03:27,300 --> 00:03:31,700
потому что моряки, которые плыли из Лимы в Лондон, остановятся в Западной Африке и

64
00:03:31,700 --> 00:03:33,700
будут думать откуда это.

65
00:03:36,670 --> 00:03:38,940
Лима - один из лучших городов для гурманов в мире,

66
00:03:38,940 --> 00:03:42,620
и это единственный город, в котором  два ресторана входят в мировую десятку,

67
00:03:42,620 --> 00:03:45,660
в том числе Central, лучший ресторан в Латинской Америке,

68
00:03:45,660 --> 00:03:49,394
который недавно был показан в третьем сезоне телепрограммы "Chef's Table".

69
00:03:49,394 --> 00:03:50,639
Это большой кактус.

70
00:03:51,280 --> 00:03:52,740
Готовы забронировать ваш билет?

71
00:03:52,740 --> 00:03:54,449
Вот что вам нужно знать перед тем как лететь сюда.

72
00:03:56,020 --> 00:04:00,419
Валюта в перу называется Nuevo Sol и 3 Nuevo Sol равняется примерно одному доллару.

73
00:04:00,640 --> 00:04:06,569
Перуанские розетки мощностью 220 вольт, и они предназначены как для американских, так и для европейских вилок.

74
00:04:06,569 --> 00:04:08,569
Два в одном.

75
00:04:08,709 --> 00:04:11,420
Помимо испанского, в Перу есть еще два основных языка:

76
00:04:11,420 --> 00:04:16,409
Кечуа - древний язык инков, произнесенный в Андах и Аймаре, один из самых старых

77
00:04:16,410 --> 00:04:18,600
языков в мире, на котором говорили в области озера Титикака,

78
00:04:19,120 --> 00:04:22,800
не говоря уже о 27 других языках в Амазонии.

79
00:04:22,800 --> 00:04:25,560
Итак, если вы лингвист или вам нравится говорить на языках,

80
00:04:25,560 --> 00:04:26,900
эта страна для вас.

81
00:04:26,900 --> 00:04:28,980
Бюджет. Перу является страной для среднего сегмента.

82
00:04:28,980 --> 00:04:33,450
Вам может легко хватить менее 50 долларов в день или даже менее  40 долларов, что делает его более дешевым, чем Чили,

83
00:04:33,450 --> 00:04:35,040
но более дороже чем в Боливии.

84
00:04:35,040 --> 00:04:38,340
Перу находится около экватора и может быть посещен круглый год.

85
00:04:38,340 --> 00:04:40,499
С июня по август в сухой сезон -

86
00:04:40,500 --> 00:04:42,160
отличный повод для похода на Тропу Инков.

87
00:04:42,160 --> 00:04:45,450
С декабря по март - это жаркий сезон. Идеально для похода на пляж.

88
00:04:45,520 --> 00:04:48,680
Вы можете сэкономить в сезоны с сентября по ноябрь

89
00:04:48,680 --> 00:04:50,700
или с марта по май, когда погода еще хорошая

90
00:04:50,700 --> 00:04:52,690
и не так много туристов.

91
00:04:52,690 --> 00:04:54,520
От океана до Анд,

92
00:04:54,520 --> 00:04:56,600
Перу имеет множество разных климатов,

93
00:04:56,600 --> 00:04:59,010
поэтому очень важно одеться потеплее.

94
00:04:59,560 --> 00:05:06,120
Нужна водонепроницаемая одежда, так как здесь много дождей и поэтому так холодно.

95
00:05:06,400 --> 00:05:11,860
Или вы можете просто подобрать хороший свитер из альпаки с одного из рынков за десять долларов США.

96
00:05:12,100 --> 00:05:15,360
Перу - это "твердая" страна, поэтому убедитесь, что у вас есть правильная обувь.

97
00:05:15,729 --> 00:05:20,480
Даже если вы планируете прогуляться по Мачу-Пикчу, наденьте солидные походные сапоги.

98
00:05:20,480 --> 00:05:22,529
Здесь много дождей. Поэтому так грязно и скользко.

99
00:05:50,909 --> 00:05:52,080
Я в нетерпении.

100
00:05:52,080 --> 00:05:53,740
Мы никогда не были в Перу до этого.

101
00:05:53,740 --> 00:05:56,838
Это дом Империи Инков.

102
00:05:56,999 --> 00:06:00,709
Это действительно то место, которое мы хотели посетить очень долгое время.

103
00:06:00,710 --> 00:06:04,129
Мы очень рады быть здесь и снимать фильм.

104
00:06:04,229 --> 00:06:06,588
Он сказал, что это часть Империи Инков,

105
00:06:06,589 --> 00:06:09,182
но Империя Инков была самой последней из многих.

106
00:06:09,182 --> 00:06:10,879
Здесь было множество цивилизаций

107
00:06:11,399 --> 00:06:14,869
в том числе здесь, в Лима, хотя это испанский колониальный город,

108
00:06:15,629 --> 00:06:17,500
он назван в честь людей Лимы.

109
00:06:17,500 --> 00:06:18,769
Мы едем увидеть

110
00:06:19,110 --> 00:06:23,060
некоторые из остатков этой цивилизации примерно с 400 года нашей эры.

111
00:06:23,060 --> 00:06:24,100
И после этого мы поедем кушать.

112
00:06:24,100 --> 00:06:26,920
Предположительно, в Лиме действительно хорошая еда.

113
00:06:41,720 --> 00:06:47,020
Первая остановка здесь, в Лиме, и наша первая остановка в Перу - Уака Пукльяна

114
00:06:47,060 --> 00:06:51,860
Это остатки храма людей Лимы, которые были первоначальными жителями города до прибытия испанцев.

115
00:06:52,100 --> 00:06:53,480
К сожалению, на сегодняшний день  это закрыто.

116
00:06:53,480 --> 00:06:55,940
Мы не можем туда подняться, и вы можете приходить сюда только с гидами

117
00:06:56,100 --> 00:07:01,609
но вы можете видеть позади нас просто огромную пирамиду, сделанную из глинобитных кирпичей.

118
00:07:01,610 --> 00:07:03,350
И это центр города.

119
00:07:03,350 --> 00:07:07,220
Мне кажется это показывает контраст между старым и новым перу.

120
00:07:07,220 --> 00:07:11,100
У нас есть самые старые и самые передовые цивилизации во всей Америке

121
00:07:11,100 --> 00:07:13,070
прямо посреди оживленного мегаполиса.

122
00:07:17,430 --> 00:07:22,880
Честно говоря, это просто поразительно, что весь этот комплекс сделан из отдельных кирпичей.

123
00:07:22,880 --> 00:07:25,100
Там сотни тысяч, если не

124
00:07:25,100 --> 00:07:29,989
миллион кирпичей, а пирамида имеет так много разных слоев.

125
00:07:30,480 --> 00:07:36,340
Должно быть, это была задача, чтобы археологически раскопать это место.

126
00:07:36,440 --> 00:07:41,600
Жалко, что мы не можем исследовать больше, но мы увидим современные слои

127
00:07:42,150 --> 00:07:44,720
перуанской истории здесь - из Испании.

128
00:08:01,659 --> 00:08:06,480
Мы только что прибыли на Пласа-де-Армас.

129
00:08:06,480 --> 00:08:09,359
И, как мы уже говорили, Перу сегодня играет в Колумбию.

130
00:08:09,360 --> 00:08:11,460
Их национальные футбольные команды стоят наготове.

131
00:08:11,460 --> 00:08:14,580
Мы не знали, что игра будет происходить, когда мы планировали этот день.

132
00:08:14,980 --> 00:08:19,200
Мы пришли сюда потому что это центр старой испанской Лимы.

133
00:08:19,320 --> 00:08:22,400
Когда испанцы завоевали инков, они перевезли столицу из Куско в Лиму.

134
00:08:22,400 --> 00:08:24,160
Они строили свои дома прямо здесь.

135
00:08:24,400 --> 00:08:27,300
В любом случае, игра вот-вот начнется. Так что давайте пойдем туда и посмотрим, что произойдет.

136
00:08:58,600 --> 00:09:02,159
Наконец-то мы нашли тихое место, поэтому я могу объяснить, почему Испанцы  здесь построили город.

137
00:09:02,160 --> 00:09:03,250
Это всё из-за реки.

138
00:09:03,250 --> 00:09:06,520
В основном, река соединяет горы до самого порта Кальяо

139
00:09:06,520 --> 00:09:07,979
и испанцы построили город на побережье.

140
00:09:08,320 --> 00:09:10,660
Они были морским народом.

141
00:09:10,660 --> 00:09:14,280
Чтобы взять серебро и золото от инков и

142
00:09:14,440 --> 00:09:15,600
отправить его в Испанию.

143
00:09:15,600 --> 00:09:17,920
Это была столица Королевства Перу.

144
00:09:17,920 --> 00:09:19,440
В то время было три королевства:

145
00:09:19,440 --> 00:09:20,200
одно в Мексике,

146
00:09:20,200 --> 00:09:22,860
одно в Лиме, и другое в Эспаньола,

147
00:09:22,860 --> 00:09:24,540
первый остров, которые испанцы колонизировали.

148
00:09:24,860 --> 00:09:27,620
И все это для того, чтобы вернуть золото в Испанию.

149
00:09:27,720 --> 00:09:30,600
Почти Испанская Южная Америка контролировалась из этого города.

150
00:09:30,600 --> 00:09:34,380
 

151
00:09:34,960 --> 00:09:37,420
Он стал очень, очень мощным, и старая столица инков Куско исчезла из истории.

152
00:09:37,420 --> 00:09:41,910
Испанская колонизация Америки была самым жестоким

153
00:09:42,070 --> 00:09:43,640
периодом в истории.

154
00:09:43,640 --> 00:09:46,800
Они уничтожили некоторые из самых невероятных

155
00:09:47,020 --> 00:09:48,670
культур коренных народов

156
00:09:48,670 --> 00:09:51,560
включая инков и ацтеков,

157
00:09:51,560 --> 00:09:53,526
и это часть истории.

158
00:09:53,526 --> 00:09:56,240
Не самая хорошая, но

159
00:09:56,440 --> 00:10:00,540
это просто интересно смотреть, как все это происходит пятьсот лет спустя.

160
00:10:13,920 --> 00:10:17,920
Мы находимся в непосредственной близости от Пуэбло Либре в Таберна-Кэйроло

161
00:10:17,920 --> 00:10:21,620
В основном это было основано итальянскими иммигрантами, которые прибыли сюда, чтобы выращивать Писко.

162
00:10:21,620 --> 00:10:23,880
Писко, возможно вы слышали о коктейле Писко сауэр,

163
00:10:23,880 --> 00:10:25,620
но на самом дела это виноградный ликёр.

164
00:10:26,200 --> 00:10:31,640
История состоит в том, что в колониальные времена испанцы не позволяли местным жителям выращивать собственное вино

165
00:10:31,640 --> 00:10:33,419
потому что они импортировали его из Испании.

166
00:10:33,730 --> 00:10:35,969
Так что местные жители делали ликёр

167
00:10:36,250 --> 00:10:40,080
из винограда вместо вина.. это Писко.

168
00:10:40,080 --> 00:10:42,270
Эти ребята приехали из Италии, чтобы вырастить Писко,

169
00:10:42,270 --> 00:10:43,890
и они все еще делают это.

170
00:10:43,890 --> 00:10:46,229
Я заказа чистый Писко

171
00:10:46,230 --> 00:10:48,120
просто чистый Писко

172
00:10:48,120 --> 00:10:50,320
а Алекс и Карлос Писко сауэр.

173
00:10:50,320 --> 00:10:53,940
Будьте здоровы. Хорошая неделя в Перу.

174
00:10:55,140 --> 00:10:57,560
Это на самом деле первый раз когда я попробовал Писко сауэр.

175
00:11:10,480 --> 00:11:12,000
Мы осознали

176
00:11:12,000 --> 00:11:18,640
что мы почти единственные люди здесь, а все остальные находятся в другой комнате и смотрят футбол.

177
00:11:18,780 --> 00:11:21,760
Это безумие, чтобы понять, насколько это важно во всем мире.

178
00:11:21,760 --> 00:11:24,800
Когда проходит Кубок мира, национальная лихорадка

179
00:11:24,860 --> 00:11:28,400
выражается в конкурентном

180
00:11:28,520 --> 00:11:32,660
, но совместном плане, и я думаю, что Америка настолько эгоистична

181
00:11:34,150 --> 00:11:35,610
с нашими видами спорта

182
00:11:35,610 --> 00:11:38,960
Мы так увлечены NFL и MLB.

183
00:11:38,960 --> 00:11:41,669
Мировая серия не является мировой серией.

184
00:11:42,070 --> 00:11:45,840
Приятно приезжать за границу и видеть, что люди так взволнованы

185
00:11:46,660 --> 00:11:48,660
футболом.

186
00:11:49,300 --> 00:11:51,260
Но только для того, чтобы исправить вас.

187
00:11:51,260 --> 00:11:55,680
Вы сказали, что вы американец, тогда как люди здесь говорят, что они тоже американцы, потому что по-испански,

188
00:11:56,110 --> 00:11:58,900
Америка это один континент, а не два.

189
00:11:58,900 --> 00:12:03,839
Несмотря на то что вы едины...вы американский гринго.

190
00:12:16,060 --> 00:12:19,240
Мы вернулись в отель.

191
00:12:19,400 --> 00:12:21,119
Хорошая работа, Перу. Хорошая работа, Колумбия

192
00:12:21,119 --> 00:12:22,920
Вы ребята включились в игру,

193
00:12:22,920 --> 00:12:25,660
но мы только начали проводить свое время здесь, в Перу.

194
00:12:25,660 --> 00:12:29,758
Я думаю просмотр футбольного матча, особенно, что мы не знали, что это произойдет,

195
00:12:30,119 --> 00:12:34,220
был довольно хорошим способом изучить то, что местные жители любят делать здесь.

196
00:12:34,220 --> 00:12:35,729
Я бы сказал, это как погружение

197
00:12:36,369 --> 00:12:37,629
прямо на дно.

198
00:12:37,629 --> 00:12:39,477
Было мило и был Писко сауэр.

199
00:12:39,477 --> 00:12:42,540
Мы собираемся заканчивать влог на этом

200
00:12:42,540 --> 00:12:43,709
и увидимся завтра, ребята.

201
00:12:44,189 --> 00:12:46,439
Мы собираемся обойти Лиму,

202
00:12:46,439 --> 00:12:50,279
который является одним из лучших городов гурманов в мире, и мы будем делать это с местными ютуберами.

203
00:12:50,279 --> 00:12:51,960
Так что увидимся завтра.

204
00:12:51,960 --> 00:12:53,219
Если вам понравилось видео,

205
00:12:53,220 --> 00:12:56,220
вы знаете что делать, ставьте лайки, делитесь с друзьями, подписывайтесь на Vagabrother

206
00:12:56,220 --> 00:12:57,899
и включите уведомления, если вы еще этого не сделали.

207
00:12:58,420 --> 00:13:03,120
И как всегда оставайтесь любопытными, продолжайте изучать..

208
00:13:03,180 --> 00:13:04,360
Доброго вечера

209
00:13:04,480 --> 00:13:06,840
Увидимся позже. Увидимся завтра Чао.

`