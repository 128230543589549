export const str_3_ru = `
1
00:00:03,360 --> 00:00:05,320
Тема 4. Погода.

2
00:00:15,260 --> 00:00:16,880
Давай поиграем снаружи.

3
00:00:18,260 --> 00:00:20,000
Извините, я не могу.

4
00:00:20,060 --> 00:00:21,560
Холодно и ветрено.

5
00:00:27,400 --> 00:00:28,940
Смотри! Идет снег.

6
00:00:31,500 --> 00:00:33,000
Он весь белый.

7
00:00:35,060 --> 00:00:36,620
Тебе нравится снег?

8
00:00:37,400 --> 00:00:38,840
Да, мне нравится.

9
00:00:39,660 --> 00:00:40,800
Пойдем!

10
00:00:45,780 --> 00:00:47,300
Можешь сделать снеговика?

11
00:00:48,180 --> 00:00:49,600
Конечно я могу.

12
00:00:58,360 --> 00:01:00,980
Снеговик! Ты в порядке.

13
00:01:01,660 --> 00:01:02,500
Спасибо.

14
00:01:06,340 --> 00:01:07,380
Это что?

15
00:01:08,060 --> 00:01:09,220
Его нос.

16
00:01:13,560 --> 00:01:15,660
Вау, нос.

17
00:01:33,940 --> 00:01:37,500
Как погода? Как погода?

18
00:01:37,540 --> 00:01:40,620
Солнечно. Солнечно.

19
00:01:41,140 --> 00:01:44,740
Как погода? Как погода?

20
00:01:44,820 --> 00:01:47,880
Пасмурно. Пасмурно.

21
00:01:48,400 --> 00:01:51,940
Как погода? Как погода?

22
00:01:51,960 --> 00:01:55,100
Идет дождь. Идет дождь.

23
00:01:55,580 --> 00:01:59,120
Как погода? Как погода?

24
00:01:59,240 --> 00:02:02,000
Идет снег. Идет снег.

25
00:02:17,000 --> 00:02:18,920
Как погода?

26
00:02:19,080 --> 00:02:22,440
Дождливо. Дождливо.

27
00:02:23,060 --> 00:02:24,820
Это твое?

28
00:02:25,080 --> 00:02:27,060
Да, это мое.

29
00:02:29,060 --> 00:02:30,920
Как погода?

30
00:02:31,140 --> 00:02:34,480
Солнечно. Солнечно.

31
00:02:35,080 --> 00:02:36,960
Это ваше?

32
00:02:37,080 --> 00:02:39,020
Нет, это не мое.

33
00:02:40,960 --> 00:02:42,920
Как погода?

34
00:02:43,060 --> 00:02:46,640
Ветренно. Ветренно.

35
00:02:47,000 --> 00:02:49,080
Это твое?

36
00:02:49,080 --> 00:02:50,880
Да, это мое.

37
00:03:05,880 --> 00:03:07,400
Как погода?

38
00:03:08,140 --> 00:03:10,700
Пасмурно, пасмурно, пасмурно.

39
00:03:12,600 --> 00:03:14,020
Как погода?

40
00:03:14,800 --> 00:03:17,360
Дождь, дождь, дождь.

41
00:03:19,260 --> 00:03:20,660
Как погода?

42
00:03:21,460 --> 00:03:24,000
Снег, снег, снег.

43
00:03:25,960 --> 00:03:27,380
Как погода?

44
00:03:27,980 --> 00:03:30,760
Солнечно, солнечно, солнечно.

45
00:03:46,560 --> 00:03:48,300
Как здесь погода?

46
00:03:48,580 --> 00:03:50,560
Как здесь погода?

47
00:03:50,560 --> 00:03:52,220
Ветрено и снежно.

48
00:03:52,220 --> 00:03:54,060
Ветрено и снежно.

49
00:03:54,160 --> 00:03:55,860
Наденьте эту шляпу.

50
00:03:55,940 --> 00:03:56,840
Да!

51
00:03:57,840 --> 00:03:59,640
Надень эти перчатки.

52
00:03:59,780 --> 00:04:00,420
Да!

53
00:04:08,860 --> 00:04:10,700
Как погода на улице?

54
00:04:10,700 --> 00:04:12,500
Как погода на улице?

55
00:04:12,620 --> 00:04:15,320
Дождливо и холодно.

56
00:04:16,279 --> 00:04:18,999
Дождливо и холодно.

57
00:04:19,980 --> 00:04:21,680
Принеси этот зонтик.

58
00:04:21,760 --> 00:04:22,640
Да!

59
00:04:23,720 --> 00:04:25,360
Принеси этот зонт.

60
00:04:25,460 --> 00:04:26,060
Да!

61
00:04:45,720 --> 00:04:47,600
Как погода?

62
00:04:50,340 --> 00:04:52,360
Идет дождь.

63
00:04:53,620 --> 00:04:55,480
О, нет.

64
00:05:00,840 --> 00:05:02,580
Как погода?

65
00:05:03,560 --> 00:05:04,700
Солнечно.

66
00:05:06,340 --> 00:05:08,180
О, нет.

67
00:05:13,340 --> 00:05:15,000
Смотри, идет дождь.

68
00:05:16,720 --> 00:05:19,480
Ладно, звучит хорошо.

69
00:05:24,500 --> 00:05:25,720
Сейчас солнечно.

70
00:05:28,160 --> 00:05:29,840
Это хорошо.

71
00:05:41,920 --> 00:05:44,740
Сварливый, Счастливый, Одурманенный.

72
00:05:45,580 --> 00:05:46,700
Приходящий.

73
00:05:47,260 --> 00:05:48,940
Давайте играть снаружи!

74
00:05:49,540 --> 00:05:51,740
Извините, мы не можем.

75
00:05:54,780 --> 00:05:56,740
Идет дождь.

76
00:05:57,260 --> 00:06:00,280
Дождя нет. Идет снег.

77
00:06:00,980 --> 00:06:02,040
Снег?

78
00:06:03,240 --> 00:06:05,020
Смотри! Это снег.

79
00:06:05,340 --> 00:06:06,680
Он весь белый.

80
00:06:12,080 --> 00:06:13,320
Хорошая шляпа!

81
00:06:16,780 --> 00:06:17,780
Это что?

82
00:06:18,140 --> 00:06:19,340
Это яблоко.

83
00:06:19,740 --> 00:06:20,920
О, нет!

84
00:06:21,000 --> 00:06:22,780
Никаких яблок!

85
00:06:25,600 --> 00:06:27,220
Вы можете помочь мне?

86
00:06:27,320 --> 00:06:30,060
Конечно. Пожалуйста.

87
00:06:30,480 --> 00:06:32,160
Ты можешь съесть это яблоко.

88
00:06:33,640 --> 00:06:34,660
О~
`