export const ruLocalization = {
    translation: {
        layouts: {
            header: {
                t_title: '@lisi4ka',
                t_about: 'Обо мне',
                t_blog: 'Блог',
                t_articles: 'Статьи',
                t_reviews: 'Разборы',
                t_youtube: 'YouTube',
                t_books: 'Книги',
                t_videos: 'Видео',
                t_sign_in: 'Войти',
            },
            footer: {
                // block 1
                t_block1_title: '@lisi4ka',
                t_block1_desc: 'Учите английский язык! Этот язык международный. Он уже давно никому не принадлежит. "Если вы знаете языки, вы везде будете как дома..." :)',
                t_block1_email: 'Емейл:',
                t_block1_phone: 'Телефон:',
                // block 2
                t_block2_title_youtube: 'YouTube',
                t_block2_link_videos: 'Видео',
                t_block2_title_blog: 'Блог',
                t_block2_link_articles: 'Статьи',
                t_block2_link_reviews: 'Разборы',
                // block 3
                t_block3_title: 'Последние статьи',
            }
        },
        pages: {
            home: {
                // main
                t_main_title: 'Учитель\nанглийского',
                t_main_subtitle: 'Юлия Зарубина',
                // about me
                t_about_title: 'Обо мне',
                t_about_description: 'Привет! Я Юлия, учитель английского языка. Я штатный учитель в средней образовательной школе. Я преподаю более трех лет. У меня есть опыт преподавания английского языка для всех возрастов и уровней средней школы, включая инклюзивность. Мои уроки всегда живые, наполнены приятными занятиями и, самое главное, мои уроки приносят пользу моим ученикам. Я использую различные методики обучения и реквизит. Я всегда вежлива и терпелива со своими учениками. Преподавание — моя страсть и хобби, поэтому мне очень повезло, что я работаю в сфере образования. Потребности в обучении моих учеников удовлетворяются за счет постановки целей, надлежащей подготовки и обучения. В урок я включаю разговор, чтение, письмо, восприятие на слух и оценку. Я использую веселые и интересные цифровые материалы в соответствии с личными потребностями/интересами. Я имею квалификацию, позволяющую обучать английскому языку. Моя квалификация также включает обучение студентов онлайн.',
                t_btn_more: 'Подробнее',
                // blocks
                t_blocks_title: 'Здесь вы найдете полезные для обучения сервисы',
                t_blocks_subtitle: 'Сайт предоставляет удобные инструменты для изучения английского языка. Все для моих любимых учеников',
                t_blocks_item1_title: 'Видео\nс субтитрами',
                t_blocks_item1_text: 'Удобный способ развития восприятия на слух',
                t_blocks_item2_title: 'Еженедельные\nобзоры',
                t_blocks_item2_text: 'Посты о книгах и фильмах, полезных для обучающихся',
                t_blocks_item3_title: 'Блог с советами\nпо обучению',
                t_blocks_item3_text: 'Интересная информация, которая поможет улучшить ваши навыки',
                t_blocks_item4_title: 'Удобный способ\nучить слова',
                t_blocks_item4_text: 'Присутствует интеграция Яндекс и Google переводчика',
                // prides
                t_prides_title: 'Моя гордость',
                t_prides_subtitle: 'Вам не нужно бороться в одиночку, у вас есть моя помощь и поддержка',
                t_prides_item1_title: '5',
                t_prides_item1_text: 'Онлайн\nобученых',
                t_prides_item2_title: '12',
                t_prides_item2_text: 'Крутых\nотличников',
                t_prides_item3_title: '4,836',
                t_prides_item3_text: 'Проведенных\nуроков',
                t_prides_item4_title: '1',
                t_prides_item4_text: 'Написанных\nстатей',
                // start
                t_start_title: 'Мой опыт для учителей',
                t_start_subtitle: 'Мои достижения, которые могут помочь в работе моим коллегам',
                t_start_download_block: 'Начните учиться сейчас,\nзарегистрируйтесь на сайте',
            },
            about: {
                // skills
                't_skills_title': 'Мое образование и навыки',
                't_skills_subtitle': 'Где и чему я училась. Результаты моего обучения',

                't_skills_item1_title': 'Образование',
                't_skills_item1_text': 'Севастопольский национальный университет ядерной энергии и промышленности, специальность "Управление информационной безопасностью"',

                't_skills_item2_title': 'Дополнительное образование',
                't_skills_item2_text': 'МГУ, программа профессиональной переподготовки "Теоретико-прагматические основы преподавания английского языка: современные подходы." ',

                't_skills_item3_title': 'Повышение квалификации',
                't_skills_item3_text': 'Муж у меня вообще самый лучий. Ни закончил ни одного университета а работает лучшим програмистом мира и вселенной. Сайт этот он мне написал.',

                't_skills_item4_title': 'Награда учитель года',
                't_skills_item4_text': 'Когда-нибудь я ее получу. Я стараюсь и я топчик. Обучу так, что носители будут принимать моих учеников за своих, и снимут с них санкции.',

                // about
                't_about_title': 'Обо мне',
                't_about_title_role': 'Учитель аглийского, мать, и любящая жена',
                't_about_title_name': 'Юлия Зарубина',
                't_about_text': 'Привет! Добро пожаловать на мой сайт и спасибо за Ваш интерес! Я рада что Вы здесь, читаете эту страницу, интересуетесь образованием и знаниями.' +
                    '\n' +
                    '\n' +
                    'Я работаю учителем аглийского более 3х лет. При этом я сама не прекращаю учиться - нет предела совершенству. На сегодняшний день готовлюсь к сдаче экзамена, который подтверждает, что мой уровень владения английским языком - C2.' +
                    '\n' +
                    '\n' +
                    'Этот сайт был написан для помощи в обучении моих учеников. Работаю над результатом, максимально включая современные технологии в процесс обучения. Лучшие инструменты для лучших учеников :)' +
                    '\n' +
                    '\n' +
                    'Изучение иностранного языка - это путь в параллельный мир, а изучение аглийского - бонус на пути к языковой свободе.',
                't_about_follow_text': 'Мои соц сети',

                // experience
                't_experience_title': 'Опыт',
                't_experience_subtitle': 'Я учитываю только преподавательскую практику.' +
                    '\n' +
                    'Не думаю, что здесь кому-то интересна моя работа в полиции. ;)',

                't_experience_school_1_title': 'МБОУ Романовская' +
                    '\n' +
                    'СОШ',
                't_experience_school_1_role': 'Учитель английского языка',
                't_experience_school_1_date': 'Сент 2020 - настоящее время',
                't_experience_school_1_text': 'Количество классов – 39. На базе 10-11 классов создано 6 профильных групп: физико-математический, естественнонаучный профили, универсально-профильная группа с углубленным изучением русского языка и истории, с углубленным изучением -углубленное изучение физики и математики.' +
                    '\n' +
                    '\n' +
                    'Всего обучается 992 человека. На уровне начального общего образования обучается 446 учащихся, на уровне основного общего образования - 488 учащихся, на уровне среднего общего образования - 58 учащихся.',
                't_experience_school_1_link': 'http://www.romanovschool.ru/',

                't_experience_school_2_title': 'Moscow State University' +
                    '\n' +
                    '\'M. V. Lomonosov\'',
                't_experience_school_2_role': 'Студент',
                't_experience_school_2_date': 'Выпуск в 2020',
                't_experience_school_2_text': 'Моско́вский госуда́рственный университе́т и́мени М. В. Ломоно́сова (с 1755 по 1917 год — Императорский Московский университет) — один из старейших и крупнейших классических университетов России, один из центров российской науки и культуры, расположенный в Москве.' +
                    '\n' +
                    '\n' +
                    'Университет включает в себя 15 научно-исследовательских институтов, 43 факультета, более 300 кафедр и семь филиалов (в их числе шесть зарубежных — пять в странах СНГ и один в Словении). С 1992 года ректором МГУ является академик Виктор Антонович Садовничий.',
                't_experience_school_2_link': 'https://www.msu.ru/',

                // questions
                't_questions_title': 'У вас есть еще вопросы?',
                't_questions_subtitle': 'Не стесняйтесь, скажите привет!',
                't_questions_btn_send': 'Написать сообщение'
            },
            blog: {
                't_blog_title': 'Я стараюсь для тебя',
                't_blog_subtitle': 'Делюсь своим опытом преподавания, знаниями, \nа также еженедельно пишу интересные и полезные советы для изучения английского языка',
                't_blog_list_btn_more': 'Читать...',

                't_filter_title': 'Конфигурация',
                't_filter_types_title': 'Типы',
                't_filter_types_item_1': 'Статьи',
                't_filter_types_item_2': 'Разборы',
                't_filter_sort_title': 'Сортировка',
                't_filter_sort_item_1': 'По дате',
                't_filter_sort_item_2': 'По алфавиту',
            },
            article: {
                t_date: "Написано",
            },
            videos: {
                't_videos_title': 'YouTube',
                't_videos_subtitle': 'Видео с интерактивными субтитрами.\n' +
                    'Смотрите, читайте, изучайте, понимайте, что сказал ваш любимый блогер. Слушайте или читайте книги.',
                't_videos_search': 'Поиск...',
                't_videos_btn_read': 'Перейти',

                't_filter_types_title': 'Уровни',
                't_filter_types_item_1': 'Уровень 1',
                't_filter_types_item_2': 'Уровень 2',
                't_filter_types_item_3': 'Уровень 3',

                't_filter_title': 'Конфигурация',
                't_filter_sort_title': 'Сортировка',
                't_filter_sort_item_1': 'По дате',
                't_filter_sort_item_2': 'По алфавиту',
            },
            video: {
                't_video_mode_pause': 'Пауза после фразы',
                't_video_mode_loop': 'Зациклить фразу',

                't_video_mode_video_hide': 'Скрыть видео',
                't_video_mode_video_show': 'Показать видео',

                't_video_mode_text_hide': 'Скрыть текст',
                't_video_mode_text_show': 'Показать текст',

                't_video_mode_align_justify': 'Выровнять',
                't_video_mode_align_left': 'Слева',
                't_video_mode_align_center': 'Центр',

                't_video_mode_keep': 'Закрепить на странице',
                't_video_mode_unkeep': 'Открепить от страницы',

                't_video_mode_dark_enable': 'Включить темный режим',
                't_video_mode_dark_disable': 'Выключить темный режим',

                't_video_mode_language': 'Язык',
                't_video_tr_yandex': 'Yandex',
                't_video_tr_google': 'Google',
            },
            error: {
                t_text: 'Страница не найдена',
                t_subtext: 'Страница, которую вы ищете, не существует',
                t_btn: 'На главную'
            }
        }
    },
};