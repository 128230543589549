export const str_2_en = `
1
00:00:01,720 --> 00:00:03,360
English Singsing.

2
00:00:05,060 --> 00:00:06,420
Body song.

5
00:00:12,120 --> 00:00:13,160
Yeah!

6
00:00:13,160 --> 00:00:14,400
Come on!

7
00:00:14,400 --> 00:00:16,400
Body song!

8
00:00:17,720 --> 00:00:19,180
Bop bop bop.

9
00:00:19,180 --> 00:00:21,000
Body body bop bop bop.

10
00:00:21,100 --> 00:00:22,320
Bop bop bop.

11
00:00:22,320 --> 00:00:24,340
Body body bop bop.

12
00:00:24,540 --> 00:00:26,020
Tap my eyes.

13
00:00:26,160 --> 00:00:27,640
Tap my ears.

14
00:00:27,820 --> 00:00:29,360
Tap my nose.

15
00:00:29,360 --> 00:00:31,060
Tap my mouth.

16
00:00:31,200 --> 00:00:34,160
Tap tap tap my body.

17
00:00:34,500 --> 00:00:35,820
Bop bop bop.

18
00:00:35,820 --> 00:00:37,160
Everybody bop bop.

19
00:00:37,840 --> 00:00:38,840
Bop bop bop.

20
00:00:38,840 --> 00:00:40,840
Body body bop bop bop.

21
00:00:41,020 --> 00:00:42,020
Bop bop bop.

22
00:00:42,140 --> 00:00:44,140
Body body bop bop.

23
00:00:44,340 --> 00:00:46,000
Shaking my head.

24
00:00:46,000 --> 00:00:47,640
Shaking my shoulders.

25
00:00:47,800 --> 00:00:49,220
Shaking my waist.

26
00:00:49,220 --> 00:00:50,920
Shaking my hips.

27
00:00:50,960 --> 00:00:54,300
Shaking Shaking Shaking my body.

28
00:00:54,440 --> 00:00:55,980
Bop bop bop.

29
00:00:55,980 --> 00:00:57,320
Everybody bop bop.

30
00:00:57,660 --> 00:00:58,800
Bop Bop bop.

31
00:00:58,920 --> 00:01:00,380
Body body bop bop bop.

32
00:01:00,380 --> 00:01:02,000
Bop Bop bop.

33
00:01:02,120 --> 00:01:03,840
Body body bop bop bop.

34
00:01:04,099 --> 00:01:05,880
Touching my arms.

35
00:01:06,060 --> 00:01:07,400
Touching my hands.

36
00:01:07,500 --> 00:01:09,140
Touching my legs.

37
00:01:09,140 --> 00:01:10,820
Touching my feet.

38
00:01:10,820 --> 00:01:13,900
Touching Touching Touching my body.

39
00:01:14,160 --> 00:01:15,340
Bop bop bop.

40
00:01:15,400 --> 00:01:17,000
Everybody bop bop.

41
00:01:17,220 --> 00:01:18,220
Yeah!
`